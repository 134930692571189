<template>
  <div class="advantages">
    <div class="advantages__header title">
      <h2>
        <span>Стать партнером компании UNIQOM</span>
      </h2>
    </div>
    <div class="advantages__items">
      <div class="advantages__item">
        <img
            src="@/assets/img/elem/ok.svg"
            class="advantages__icon icon-ok"
        />
        <div class="advantages__header">
          Японское качество
        </div>
        <div class="advantages__text">
          Компания является лидером автомобильного рынка.
        </div>
      </div>
      <div class="advantages__item">
        <img
            src="@/assets/img/elem/ok.svg"
            class="advantages__icon icon-ok"
        />
        <div class="advantages__header">
          Выгодные цены
        </div>
        <div class="advantages__text">
          Многие бренды поставляются на российский рынок по эксклюзивным ценам.
        </div>
      </div>
      <div class="advantages__item">
        <img
            src="@/assets/img/elem/ok.svg"
            class="advantages__icon icon-ok"
        />
        <div class="advantages__header">
          Высокие стандарты
        </div>
        <div class="advantages__text">
          Компания основана в 1995 году, ее основная цель - высокий уровень качества товаров.
        </div>
      </div>
    </div>

    <div class="advantages advantages__final">
      <button class="primitive" @click="feedbackOpen">
        Оставить заявку
      </button>
    </div>
  </div>
</template>

<script>

export default {
  methods: {
    feedbackOpen(event) {
      event.preventDefault();
      this.$emit('feedback', { enabled: true });
    },
  }
}
</script>