<template>
  <div class="container">
    <div class="container__liner">
      <div class="catalog">
        <div class="catalog__pack">
          <div class="catalog__sidebar">
            <div
                v-if="categories"
                class="catalog__sidebar-sections accordion"
            >
              <div
                  class="catalog__sidebar-item"
                  v-for="(tab, index) in categories"
                  :class="{ __active : openMoreTabs(tab) }"
                  :key="index"
              >
                <a
                    :href='`/catalog/${tab.id}`'
                    class="accordion__head"
                    :class="{ accordion__head_switcher : getCurrentTab(tab, tab.child) }"
                    @click="showMoreTabs($event, tab)"
                >
                  {{ tab.title }}
                </a>
                <div v-if="tab.child" class="accordion__content">
                  <a
                      v-for="(type, index) in tab.child"
                      :key="index"
                      :href="'/catalog/' + type.id"
                      class="catalog__sidebar-child"
                      :class="{ 'catalog__sidebar-child_current': getCurrentTab(type, true) }"
                  >
                    {{type.title}}
                  </a>
                </div>
              </div>
            </div>
            <div class="catalog__sidebar-aside">
              <a href="/map" class="catalog__sidebar-pointer attractive"> Где купить/заменить </a>
            </div>
          </div>
          <div class="catalog__main">

            <div v-if="loading" class="loader loader-catalog-list">
              <div class="box box-flex" style="margin-bottom: 50px">
                <div class="item load-skeleton" style="height: 35px"></div>
                <div class="item item-flex" style="height: 400px">
                  <div class="item item-full item-col-3 load-skeleton"></div>
                  <div class="item item-full item-col-3 load-skeleton"></div>
                  <div class="item item-full item-col-3 load-skeleton"></div>
                </div>
                <div class="item item-flex" style="height: 400px">
                  <div class="item item-full item-col-3 load-skeleton"></div>
                  <div class="item item-full item-col-3 load-skeleton"></div>
                  <div class="item item-full item-col-3 load-skeleton"></div>
                </div>
              </div>
            </div>

            <template v-else>
              <div class="catalog__main-title">
                {{ currentTab }}
              </div>

              <div
                  class="catalog__main-description"
                  v-if="description.content"
              >
                <div v-html="description.content">
                  <!-- Описание категории -->

                </div>
              </div>

              <div
                class="catalog__main-search uk-margin uk-flex uk-flex-wrap"
              >
                <input
                  class="uk-width-1-3@l uk-width-1-1 uk-input uk-margin uk-margin-right"
                  placeholder="TCL Power Coolant, PC2-CR"
                  v-model="input"
                />
                <div class="catalog__main-buttons uk-margin-bottom">
                  <button
                    :disabled="input.length <= 2"
                    class="attractive attractive-small uk-margin-right"
                    @click="makeSearch(true)"
                  >
                    Поиск
                  </button>
                  <button
                    :disabled="!searched"
                    class="attractive attractive-small"
                    @click="makeSearch(false)"
                  >
                    Сбросить
                  </button>
                </div>
              </div>

              <div
                  class="catalog__main-items"
                  v-if="catalog.length"
              >
                <a
                    v-for="(item, index) in catalog"
                    :key="index"
                    :href="`/catalog/product/${item.id}`"
                    class="thing catalog__main-item"
                >
                  <div class="thing__symbols"></div>
                  <img
                      v-if="item.file"
                      class="thing__picture"
                      :alt="item.file"
                      :src="getImage('products', item.file)"
                  />
                  <div class="thing__header">{{ item.title }}</div>
                  <div class="thing__features">
                    Объем:
                    <template v-for="(article, ind) in item.articles">
                      <span :key="ind">{{ +article.volume }}</span>
                      {{ 'л' }}<template v-if="ind + 1 < item.articles.length">, </template>
                    </template>
                  </div>
                </a>
              </div>
              <div class="catalog__main-items" v-else>
                <span class="catalog__empty">Товар не найден</span>
              </div>
            </template>
          </div>
        </div>

        <pagination
            v-if="countItems > limit"
            :currentPage="thisPage"
            :countItems="countItems"
            :limitItems="limit"
            @switchPage="thisPage = $event.page"
        />

      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '../Pagination'
import utils from '../../utils';

export default {
  name: 'catalog-list',
  components: {
    Pagination
  },
  data() {
    return {
      loading: false,
      thisPage: 1,
      countItems: null,
      limit: 12,
      categories: null,
      thisCategory: null,
      currentTab: null,
      catalog: [],
      description: {},
      input: '',
      searchQuery: '',
      searched: false,
    }
  },
  methods: {
    makeSearch(search) {
      this.thisPage = 1
      this.searched = search?true:false
      this.input = search?this.input:''
      this.searchQuery = this.input
      this.getInitDataProducts()
    },
    showMoreTabs(event, tab) {
      if (tab.child) {
        event.preventDefault()
        event.target.parentElement.classList.toggle('__active')
      }
    },
    openMoreTabs(tab) {
      if (tab.child) {
        return tab.child.find(child => {
          return child.id === this.thisCategory
        })
      }
      return false
    },
    async getInitDataProducts() {
      this.loading = true
      const { fetchData } = utils()
      let target = []
      let api_products = `products`

      target.push(`limit=${this.limit}`)
      target.push(`page=${this.thisPage}`)
      target.push(`breadcrumb=${true}`)

      if (this.searched) target.push(`search=${this.searchQuery}`)

      if (this.thisCategory) {
        target.push(`category=${this.thisCategory}`)
      }

      api_products = api_products.concat(`?${target.join('&')}`)
      let response = await fetchData('get', api_products)
      if (response) {
        this.catalog = response.data
        this.countItems = response.count
        if (response.breadcrumb.length)
          this.$emit('breadcrumb', { breadcrumb : response.breadcrumb })

      }
      setTimeout(() => this.loading = false, 450);
    },
    async getInitDataCategories() {
      const { fetchData } = utils()

      let target = `categories?tree=true`
      let response = await fetchData('get', target)
      if (response) {
        this.categories = response.data
      }
    },
    getImage(type, name) {
      const { getStaticImage } = utils()
      return getStaticImage(type, name)
    },
    getCurrentTab(tab, flag) {
      if (this.thisCategory === tab.id) {
        this.currentTab = tab.title
        return flag
      }
      return tab.child
    },
    async getContentPageByCategory() {
      const { fetchData } = utils()
      let target = `content/categories?id=${this.thisCategory}`
      this.description = await fetchData('get', target)
    }
  },
  watch: {
    thisPage() {
      this.getInitDataProducts();
    },
  },
  beforeMount() {
    this.currentTab = 'Вся продукция'
    if (this.$route.params.category) {
      this.currentTab = null
      this.thisCategory = +this.$route.params.category
    }
    this.getInitDataCategories()
    this.getContentPageByCategory()
    this.getInitDataProducts()
  },
}
</script>
