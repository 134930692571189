<template>
  <div class="stock__wrapper">
    <div class="stock__holder">
      <div class="stock__heading">
        <div class="stock__heading-item">Для вашего автомобиля подходит:</div>
        <div class="stock__heading-item">Продукция TCL</div>
      </div>
      <div
        class="stock__output"
        v-if="data.length > 0"
      >
        <div
          class="stock__output-row"
          v-for="(item, index) in data"
          :key="index"
        >
          <div class="stock__output-item">
            {{ item.category }}
          </div>
          <div class="stock__output-item">
            <div class="stock__inner-table">
              <div
                  v-for="(row, index) in item.rows"
                  :key="index"
              >
                <div class="stock__inner-table-head">
                  Назначение: {{ typeReplace(row.purpose, row.transmis) }}
                </div>
                <div class="stock__inner-table-row">
                  <div class="stock__inner-table-cell">
                    <div class="stock__inner-unit-name">Параметры автомобиля:</div>
                    <div class="stock__inner-unit-requirements">
                    <span
                        class="uk-flex uk-flex-between"
                        v-for="(req, index) in row.requirements"
                        :key="index"
                    >
                      <span>{{req.title}}</span>
                      <span v-if="req.value !== '-'" style="text-align: right">{{req.value}}</span>
                    </span>
                    </div>
                  </div>
                  <div class="stock__inner-table-cell">
                    <div class="stock__output-things">
                      <div
                          class="stock__output-thing"
                          v-for="(product, index) in row.products"
                          :key="index"
                      >
                        <a :href="`/catalog/product/${product.id}`">
                          <img :src="getImage('products', product.file)" class="stock__output-photo">

                          <div class="stock__output-title">
                            {{ product.title }}
                          </div>
                          <div class="stock__output-description">
                            Объем:
                            <template v-for="(vol, ind) in product.volumes">
                              <span :key="ind">{{ +vol }}</span>
                              {{ 'л' }}<template v-if="ind + 1 < product.volumes.length">, </template>
                            </template>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div 
        class="stock__output"
        v-else
      >
        <div class="stock__empty">По вашему запросу ничего не найдено</div>
      </div>
    </div>
  </div>
</template>

<script>
import utils from '../utils'

export default {
  name: 'choose-table',
  props: {
    initData: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      data: [],
    }
  },
  methods: {
    getImage(type, name) {
      const { getStaticImage } = utils()
      return getStaticImage(type, name)
    },
    dataProcessing() {
      let processedData = []
      let bufferData = []
      this.initData.forEach(item => {

        let row = {
          category: item.category,
          products: item.items,
          transmis: item.transmission,
          purpose: item.type,
          requirements: [],
        }

        row.requirements.push( { title:  this.typeReplace(item.type, item.transmission), value: '-' } )
        row.requirements.push( { title: 'Объем c учетом фильтра : ', value: item.volume} )
        row.requirements.push( { title: 'Трансмиссия (короб. передч): ', value: item.transmission} )
        row.requirements.push( { title: 'Дифференциал (привод): ', value: item.drive} )
        if (item.wheel) row.requirements.push( { title: 'Расположение руля: ', value: item.wheel==='RHD' ? 'Правый':'Левый'} )
        row.requirements.push( { title: 'Кузов: ', value: item.body} )
        row.requirements.push( { title: 'Двигатель: ', value: item.engineTitle} )
        row.requirements.push( { title: 'Объем двигателя: ', value: item.engineVolume} )

        row.requirements = row.requirements.filter(elem => (elem.value))

        bufferData.push(row)
      })

      bufferData = this.groupArrayOfObjects(bufferData, 'category')

      Object.entries(bufferData).forEach(item => {
        processedData.push({ category: item[0], rows: item[1] })
      })
      this.data = processedData
    },
    groupArrayOfObjects(list, key) {
      return list.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },
    typeReplace(type, transmis) {
      switch (type) {
        case 'engine':
          return 'Двигатель'
        case 'transmission':
          return 'Коробка передач ' + transmis
        case 'transfer':
          return 'Раздаточная коробка'
        case 'drive_front':
          return 'Дифференциал, передний привод'
        case 'drive_back':
          return 'Дифференциал, задний привод'
        default:
          return null
      }
    }
  },
  beforeMount() {
    this.dataProcessing()
  },
  created() {

  },
}
</script>