<template>
  <div id="assortment" class="assortment" v-if="catalogs">
    <div class="assortment__container container">
      <div class="assortment__inside">
        <div class="assortment__title title">
          <h2>
            <span>Каталог товаров TCL</span>
          </h2>
        </div>
        <div class="assortment__content">
          <div class="assortment__heading heading">
            Все технические жидкости<br>для вашего автомобиля<br>в одном бренде
          </div>
          <div class="assortment__items">
            <div class="assortment__inner">
              <a
                  v-for="(item, index) in catalogs.data"
                  :key="index"
                  :href="item.href"
                  class="assortment__item"
              >
                <div class="assortment__top"></div>
                <div class="assortment__header">{{ item.title }}</div>
                <div class="assortment__bottom"></div>
                <img :src="require(`@/assets/img/widget/${item.image}`)" :alt="item.image"/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WidgetCatalogJson from '@/assets/data/widget_catalog.json';

export default {
  data() {
    return {
      catalogs: WidgetCatalogJson
    }
  }
}

</script>