<template>
  <div
      class="feedback"
      :class="{'feedback__fixed' : fixed, 'feedback__open' : opened }"
      @click="closeFixed"
  >
    <div id="request" class="popup">
      <div class="request__letter">
        <div class="request__items">

          <div class="request__name" :class="{error : errors && errors.name}">
            <input
                type="text"
                class="field field_elegant"
                placeholder="Ваше имя"
                v-model="feedback.name"
            />
            <div class="request__error" v-if="errors && errors.name">
              {{ errors.name }}
            </div>
          </div>

          <div class="request__phone" :class="{error : errors && errors.phone}">
            <input
                type="text"
                class="field field_elegant"
                placeholder="Телефон"
                v-mask="'+7 (###) ###-##-##'"
                v-model="feedback.phone"
            />
            <div class="request__error" v-if="errors && errors.phone">
              {{ errors.phone }}
            </div>
          </div>

          <div class="request__mail" :class="{error : errors && errors.email}">
            <input
                type="text"
                class="field field_elegant"
                placeholder="E-mail"
                name="email"
                v-model="feedback.email"
            />
            <div class="request__error" v-if="errors && errors.email">
              {{ errors.email }}
            </div>
          </div>

          <div v-if='partner' class="request__city" :class="{error : errors && errors.city}">
            <input
                type="text"
                class="field field_elegant"
                placeholder="Ваш город"
                name="city"
                v-model="feedback.city"
            />
            <div class="request__error" v-if="errors && errors.city">
              {{ errors.city }}
            </div>
          </div>

          <div class="rating__comment" :class="{error : errors && errors.message}">
            <textarea
                cols="40"
                rows="5"
                placeholder="Комментарий"
                class="textspace"
                v-model="feedback.message"
            >
            </textarea>
            <div class="request__error" v-if="errors && errors.message">
              {{ errors.message }}
            </div>
          </div>

        </div>

        <div class="request__confirm">
          <div
              class="request__text"
              v-if="request_message"
              :class="{'request__error' : errors, 'request__success' : !errors}"
          >
            {{ request_message }}
          </div>

          <label class="custom-ui custom-ui_colored">
            <div class="request__check">
              <input
                  id="check"
                  class="custom-ui__input"
                  type="checkbox"
                  name="check"
                  checked
                  @change="disabled = !disabled"
              />
              <label class="custom-ui__presence" for="check"> </label>
              <a href="/consent" class="custom-ui__text">
                Я даю согласие на обработку персональных данных
              </a>
            </div>
          </label>
        </div>
        <button class="request__final primitive" type="submit" @click="recaptcha" :disabled="disabled">
          <span class="loader" v-if="spin">
            <span class="lds-ripple"><div></div><div></div></span>
          </span>
          <span>Отправить заявку</span>
        </button>
      </div>
      <a
          v-if="fixed"
          href="#"
          @click="close"
          class="closeme popup__closeme"
      >
      </a>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    fixed: {
      type: Boolean,
      default: false,
    },
    opened: {
      type: Boolean,
      default: false,
    },
    partner: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      feedback: {
        name: null,
        phone: null,
        email: null,
        message: null,
        type: null,
      },
      spin: false,
      errors: null,
      request_message: null,
      disabled: false,
    }
  },
  created() {
    this.initForm();
  },
  methods: {
    close(event) {
      event.preventDefault();
      this.$emit('close', { enabled: false })
    },
    closeFixed(event) {
      if (this.fixed)
        if (!event.target.closest('.popup'))
          this.$emit('close', { enabled: false })
    },
    initForm: function() {
      this.feedback.type = 1

      if (this.partner) {
        this.feedback.city = null
        this.feedback.type = 2
      }
    },
    async postFeedbackForm(data) {
      this.errors = null
      this.request_message = null
      const api = `${process.env.VUE_APP_BACKEND_URL}/post-form`
      await axios
          .post(api, data)
          .then((response) => {
            let data = response.data
            this.spin = false
            if (data.success) {
              for (let key in this.feedback)
                this.feedback[key] = null
              this.initForm()
              this.request_message = data.message
              this.$emit('close', { enabled: false })
            } else {
              if (data) {
                this.errors = data.inputs
                this.request_message = data.message
              }
            }
          });
    },
    async recaptcha() {
      this.spin = true
      await this.$recaptchaLoaded()
      this.feedback.token = await this.$recaptcha('login')
      await this.postFeedbackForm(this.feedback);
    },
  }
}
</script>

<style>


</style>