<template>
    <div>
        <div style="margin: 20px;" v-show="showQr">
            <VueQRCodeComponent :text="valueQr" :size="size" level="H" id="qrcodeImage"/>
        </div>
        <!-- <div class="button-download-pdf" v-if="!widthScreen">
            <a class="pdf-button" :href="downloadUrl" download="certificate.pdf">Скачать PDF сертификат</a>
        </div> -->
        <div>
            <iframe :src="pdfUrl" height="1200px" width="100%"></iframe>
        </div>
        
    </div>
</template>

<script>
import axios from 'axios';
import { PDFDocument } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';

import VueQRCodeComponent from 'vue-qrcode-component';

import '@/assets/styles/certs.sass';

export default {
  name: 'certs-check',
  data() {
    return {
        inn: null,
        data: null,
        pdfBytes: null,
        valueQr: '',
        size: 200,
        showQr: false,
        widthScreen: true
    }
  },
  components: {
    VueQRCodeComponent,
  },
  methods: {
    async checkData(inn) {
        try {
            await axios
                .get(`${process.env.VUE_APP_BACKEND_URL}/get/cert?inn=${inn}`)
                .then((response) => {
                    if(response.data?.data.length <= 0) {
                        this.$router.push('/certs');
                    }
                    this.data = response.data?.data[0];
                    this.valueQr = this.valueQr+`${inn}`;
                    
                });
            if(this.data) {
                this.modifyAndDisplayPDF();
            }
        } catch (e) {
            console.log('We have the error', )
        }
    },
    async modifyAndDisplayPDF() {
        try {
            const localPdfPath = '/cert.pdf';
            const existingPdfBytes = await fetch(localPdfPath).then((res) => res.arrayBuffer());
            const inputPdfBytes = new Uint8Array(existingPdfBytes);
            const pdfDoc = await PDFDocument.load(inputPdfBytes);

            const fontBytes = await fetch('/MuseoSansCyrl-500.ttf').then((res) => res.arrayBuffer());
            pdfDoc.registerFontkit(fontkit);
            const customFont = await pdfDoc.embedFont(fontBytes)

            const page = pdfDoc.getPages();
            page[0].setFont(customFont);

            const name = this.data.name;
            const inn = this.data.inn.toString();
            const end = this.data.date_end.date;
            
            const date = new Date(end);
            const options = { day: 'numeric', month: 'long', year: 'numeric' };
            const formattedDate = date.toLocaleDateString('ru-RU', options);

            page[0].drawText(name, { x: 180, y: 435, size: 18 });
            page[0].drawText(`ИНН: ${inn}`, { x: 180, y: 390, size: 18 });
            page[0].drawText(formattedDate, { x: 50, y: 190, size: 14 });

            const qrCodeImageElement = document.getElementById('qrcodeImage');
            const letQr = qrCodeImageElement.querySelector('img[src]').getAttribute('src');
            const qrCodeImageBytes = await fetch(letQr).then((res) => res.arrayBuffer());

            const qrCodeImage = await pdfDoc.embedPng(qrCodeImageBytes);
            page[0].drawImage(qrCodeImage, {
                x: 50,
                y: 50,
                width: 100,
                height: 100
            });

            this.pdfBytes = await pdfDoc.save();

        } catch (error) {
            console.error('Ошибка обработки PDF', error);
        }
    },
  },
  metaInfo: {
    title: 'Генерация сертификата дистрибьютора',
    titleTemplate: `%s - ${process.env.VUE_APP_NAME}`,
  },
  computed: {
    pdfUrl() {
        return this.pdfBytes ? URL.createObjectURL(new Blob([this.pdfBytes], { type:'application/pdf' })) : null;
    },
    downloadUrl() {
        return this.pdfBytes ? URL.createObjectURL(new Blob([this.pdfBytes], { type: 'application/pdf' })) : null;
    }
  },
  mounted() {
    this.checkData(this.$route.query.inn);
    this.valueQr = window.location.protocol + '//' + window.location.host + window.location.pathname + '?inn=';
    if(window.screen.width < 1024) {
        this.widthScreen = false;
    }
  }
}
</script>


<style scoped>

.button-download-pdf {
    width: 100%;
    height: 80vh;
    max-width: 1920px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* padding: 24px 24px; */
    /* border: 1px solid #333333; */
    border-radius: 4px;
    /* margin-top: 70px; */
    -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
}

.pdf-button {
    font-size: 24px;
    margin: 0 auto;
    width: 100%;
    padding: 24px 24px;
    border: none;
    background: transparent;
    background: #2e93a0;
    min-width: 150px;
    color: white;
    border-radius: 20px;
    cursor: pointer;
    font-size: 18px;
    -webkit-text-shadow: 0px 0px 4px rgba(0, 0, 0, 1);
    -moz-text-shadow: 0px 0px 4px rgba(0, 0, 0, 1);
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 1);
}

</style>