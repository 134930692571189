<template>
  <div class="partners">
    <div class="container">
      <div class="container__liner">
        <div id="navigation">
          <div class="breadcrumbs">
            <a itemprop="itemListElement" href="/" class="breadcrumbs-item">Главная</a>
            <span class="breadcrumbs-item">Партнерам</span>
          </div>
        </div>

        <div class="title">
          <h1 id="pagetitle" class="entry">
            <span>Как стать партнером TCL</span>
          </h1>
        </div>
      </div>
    </div>

    <div class="container ">
      <div class="container__liner">
        <div class="entry">
          <div class="entry__content" v-html="content">
            <!-- Содержание страницы -->


          </div>
        </div>
        <div class="request">
          <div>
            <div class="title">
              <h2>
                <span>Стать партнером компании TCL</span>
              </h2>
            </div>

            <Feedback :partner="true" />
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Feedback from "../components/Feedback";
import utils from '../utils';

export default {
  components: {
    Feedback
  },
  data() {
    return {
      content: null
    }
  },
  methods: {
    async getInitContentPage() {
      const { fetchData } = utils()
      this.content = await fetchData('get', 'content/partners')
    }
  },
  beforeMount() {
     this.getInitContentPage();
  },
  metaInfo: {
    title: 'Партнерам',
    titleTemplate: `%s - ${process.env.VUE_APP_NAME}`,
  },
}
</script>