<template>
  <div class="about">
    <div class="container">
      <div class="container__liner">

        <div id="navigation">
          <div class="breadcrumbs">
            <a href="/" class="breadcrumbs-item">Главная</a>
            <span class="breadcrumbs-item">О компании</span>
          </div>
        </div>

        <div class="title">
          <h1 id="pagetitle" class="entry">
            <span>О компании TCL</span>
          </h1>
        </div>

      </div>
    </div>

    <div class="container ">
      <div class="container__liner">
        <div class="entry">
          <div class="entry__content">
            <p class="entry__strong">
              Тормозные жидкости и антифризы TCL на протяжении более полувека являются лидерами в своём сегменте продаж.
              Сегодня TCL – это знак качества, проверенный временем.
            </p>
            <p style="text-align: center;">
              <img width="585" height="390" alt="image" src="@/assets/img/about/about-1.jpg">
            </p>
            <p>
              Компания TANIKAWA YUKA KOGYO CO., LTD является производителем охлаждающей и тормозной жидкости, а также
              автомобильных масел под брендом TCL. Компания была основана в апреле 1949 года. С момента её основания
              является лидером в отрасли автохимии и широко известна как в Японии, так и за рубежом.
            </p>
            <p>
              Многолетний опыт в индустрии автохимии дает компании Tanikawa Yuka Kogyo Co., Ltd явное преимущество
              перед конкурентами, а также позволяет применять накопленные знания и результаты исследований при разработке
              новых видов продукции. Ввиду повышенного спроса на рынке автомасел, в 2017 году были созданы и запущены в
              производство моторные и трансмиссионные масла TCL. Они предназначены для обслуживания легковых автомобилей
              японских марок. Новые продукты, продолжая традиции TCL, отличаются высоким качеством, сертифицированы по
              международным стандартам и производятся исключительно в Японии.
            </p>
            <div class="entry__images">
              <img alt="image" src="@/assets/img/about/about-2.png">
            </div>
            <h2 class="entry__head">
              <span>История компании TCL</span>
            </h2>
            <div class="stage">
              Становление компании.
            </div>
            <p>
              Поражение во Второй Мировой войне негативно отразилось на экономическом состоянии Японии. В условиях
              послевоенной оккупации промышленность ориентировалась на потребности армии США, чьи военные базы были
              разбросаны по всей территории Японии. Необходимо было обслуживать многочисленную военную технику
              американских войск. Однако, в Японии на тот момент не производились антифризы и тормозные жидкости
              подходящего качества.
            </p>
            <p>
              Чтобы удовлетворить возникший спрос, в 1949 году в пригороде Токио в химической лаборатории
              исследовательского института Tanikawa Yuka Research Institute начали разработку и производство тормозных
              жидкостей и антифризов. Данные продукты вскоре завоевали популярность у армии США, что позволило
              компании в короткие сроки стать основным поставщиком американских вооруженных сил. Название института дало
              имя бренду TCL, что расшифровывается как Tanikawa Chemical Laboratory – химическая лаборатория Таникава.
            </p>
            <p class="entry__images">
              <img width="360" alt="image" src="@/assets/img/about/about-3.png" height="350">
              <img width="360" alt="image" src="@/assets/img/about/about-4.png" height="350">
            </p>
            <p>
              Спустя 8 лет, в 1957 году нефтехимик Рокуйоши Таникава стал основателем компании Tanikawa Yuka Kogyo
              Co.,Ltd. В окрестностях города Йокогама был открыт завод Tsurimi, в котором установили дистилляционное
              оборудование для тщательной очистки сырья – это позволило реорганизовывать производство и сделать его
              непрерывным.
            </p>
            <p>
              Первые сертификаты качества по японскому индустриальному стандарту JIS (Japanese Industrial Standard)
              компания получила уже в 1966 году – JIS 2233 для тормозной жидкости, и в 1969 году – JIS 2234 для антифриза.
              А в 1973 году был получен первый сертификат Министерства Транспорта США – DOT. Выпуск качественной продукции,
              соответствующей высоким японским и американским стандартам, сделал возможным выход компании на международный уровень.
            </p>
            <div class="custom-dl">
              <div class="custom-dl__item">
                <div class="custom-dl__dt">
                  1957 г
                </div>
                <div class="custom-dl__dd">
                  Основание компании Tanikawa Yuka Kogyo Co.,Ltd. Начало работы завода Tsurimi
                </div>
              </div>
              <div class="custom-dl__item">
                <div class="custom-dl__dt">
                  1966 г
                </div>
                <div class="custom-dl__dd">
                  Получен сертификат качества для тормозной жидкости JIS 2233
                </div>
              </div>
              <div class="custom-dl__item">
                <div class="custom-dl__dt">
                  1969 г
                </div>
                <div class="custom-dl__dd">
                  Получен сертификат качества для антифриза JIS 2234
                </div>
              </div>
              <div class="custom-dl__item">
                <div class="custom-dl__dt">
                  1973 г
                </div>
                <div class="custom-dl__dd">
                  Получен сертификат Министерства Транспорта США для тормозной жидкости DOT
                </div>
              </div>
              </div>
            <div class="stage">
                Активный рост.
            </div>
            <p>
                Стремительный рост количества автомобилей, выпускаемых японскими заводами, обеспечил выгодные условия
                для развития компании Tanikawa Yuka Kogyo. В 80-х годах прошлого века на фоне увеличивающегося спроса на
                японские автомобили и, следовательно, на автомобильные жидкости, компания открыла второй завод под названием
                Kanazawa и по праву заняла ведущее место на японском рынке автохимии.
            </p>
            <p>
                В начале 1990-х годов тормозные жидкости, производимые компанией, получили маркировку DOT3, DOT4, DOT5.1 и
                успешно прошли сертификационный тест авторитетной американской лаборатории Case Consulting Laboratories Inc.
            </p>
            <p>
                В связи с ростом продаж компании первый завод Tsurimi был расширен. А в начале нового тысячелетия продукция
                компании прошла сертификацию Международной организации по стандартизации, получив стандарт ISO 9001.
            </p>
            <p>
                Подобные достижения стали возможны благодаря строго регламентированной технологии производства, а также
                собственной исследовательской лаборатории, которая позволяет непосредственно применять новейшие достижения
                науки.
            </p>
            <img src="@/assets/img/about/about-5.jpg" alt="image">
            <div class="custom-dl">
              <div class="custom-dl__item">
                <div class="custom-dl__dt">
                    1982 г
                </div>
                <div class="custom-dl__dd">
                  Был открыт второй завод TCL - Kanazawa
                </div>
                </div>
              <div class="custom-dl__item">
                <div class="custom-dl__dt">
                  1992 г
                </div>
                <div class="custom-dl__dd">
                  Получены допуски к производству тормозных жидкостей классов DOT3,4,5.1
                </div>
              </div>
              <div class="custom-dl__item">
                <div class="custom-dl__dt">
                  2002 г
                </div>
                <div class="custom-dl__dd">
                  Получен сертификат ISO 9001, номер JCQA-1161
                </div>
              </div>
              <div class="custom-dl__item">
                <div class="custom-dl__dt">
                  2017 г
                </div>
                <div class="custom-dl__dd">
                  Начало производства моторного и трансмиссионного масла
                </div>
              </div>
                <p>
                  На данный момент компания производит в месяц более 650 тонн готовой продукции, которую закупают такие
                  организации как Mitsui Bussan Chemicals Co., Ltd, SPK Corporation, Kawasaki Tsurumi Rinko Bus K.K.,
                  Komatsu Trading Corporation и многие другие японские и международные компании.
                </p>
              </div>
            </div>
          </div>

        <widget-articles
            class="expert__light"
            title="События компании TCL"
            type="1"
        />
        <widget-partners @feedback="feedbackOpen"/>

      </div>
    </div>
  </div>
</template>

<script>
import WidgetArticles from '../components/WidgetArticles'
import WidgetPartners from '../components/WidgetPartners'

export default {
  name: 'about',
  components: {
    WidgetArticles,
    WidgetPartners
  },
  methods: {
    feedbackOpen(data) {
      this.$emit('feedback', { enabled: data.enabled });
    },
  },
  metaInfo: {
    title: 'О компании',
    titleTemplate: `%s - ${process.env.VUE_APP_NAME}`,
  },
}
</script>


