<template>
  <div :id="`modal-gallery-images-${id}`" class="uk-flex-top uk-padding-small modal-gallery" uk-modal>
    <div class="uk-modal-dialog uk-margin-auto-vertical">
      <button class="uk-modal-close-default uk-close-large" type="button" uk-close></button>
      <div :uk-slideshow="`animation: slide;  index: ${ index }`">
        <div
            class="uk-position-relative uk-visible-toggle uk-light"
            tabindex="-1"
            v-if="initData"
        >
          <ul
              v-if="!thisDocuments"
              class="uk-slideshow-items"
              uk-height-viewport="offset-bottom: 5"
          >
            <li v-for="(file, index) in initData.files" :key="index">
              <img :src="getImage('products',  concat_path(initData, file))" :alt="file">
            </li>
          </ul>

          <ul v-else class="uk-slideshow-items document-read" uk-height-viewport="offset-bottom: 5">
            <li v-for="(item, index) in initData.data" :key="index">
               <span class="file-pdf">
                  <pdf
                      :source="getImage('certificates', item.filename)"
                      :page="1"
                  />
              </span>
              <div uk-spinner></div>
              <div class="uk-overlay uk-overlay-primary uk-position-bottom uk-text-center">
                <a :href="getImage('certificates', item.filename)" class="uk-margin-remove" download>
                  <u>Ссылка на скачивание</u>
                </a>
              </div>
            </li>
          </ul>


          <div class="uk-hidden@s uk-light">
            <a class="uk-position-center-left uk-position-small" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
            <a class="uk-position-center-right uk-position-small" href="#" uk-slidenav-next uk-slideshow-item="next"></a>
          </div>
          <div class="uk-visible@s">
            <a class="uk-position-center-left-out uk-position-small" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
            <a class="uk-position-center-right-out uk-position-small" href="#" uk-slidenav-next uk-slideshow-item="next"></a>
          </div>
        </div>
        <ul class="uk-slideshow-nav uk-dotnav uk-flex-center"></ul>
      </div>
    </div>
  </div>
</template>

<script>
import pdf from 'vue-pdf-embed/dist/vue2-pdf-embed'
import utils from '../utils';

export default {
  name: 'modal-gallery',
  props: {
    id: {
      default: 'single',
      type: String,
    },
    thisDocuments: {
      default: false,
      type: Boolean
    },
    index: {
      default: 0,
      type: Number
    },
    initData: null,
  },
  components: {
    pdf
  },
  methods: {
    getImage(type, name) {
      const { getStaticImage } = utils()
      return getStaticImage(type, name)
    },
    concat_path(article, file) {
      return `${article.id}/${file}`
    },
  }
}
</script>