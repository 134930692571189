<template>
  <div class="policy">
    <div class="container">
      <div class="container__liner">
        <div id="navigation">
          <div class="breadcrumbs">
            <a itemprop="itemListElement" href="/" class="breadcrumbs-item">Главная</a>
            <span class="breadcrumbs-item">Политика защиты персональных данных</span>
          </div>
        </div>

        <div class="title">
          <h1 id="pagetitle" class="entry">
            <span>Политика защиты персональных данных</span>
          </h1>
        </div>
      </div>
    </div>

    <div class="container ">
      <div class="container__liner">
        <div class="entry">
          <div class="entry__content">
            <p class="entry__strong">
              Цель публикации Политики защиты персональных данных состоит в том, чтобы объяснить, какие именно виды
              данных мы собираем у посетителей нашего сайта, и как эта информация используется.
            </p>
            <p><strong>I. Общие положения</strong>.</p>
            <p>
              Настоящая Политика защиты персональных данных (далее — «Политика») является официальным документом
              ООО «Юник Продактс» (ОГРН 1162536098779 ИНН 2543107060), которое находится по адресу: 690089,
              г. Владивосток, ул. Тухачевского, д. 48а, далее — «Компания»), разработана в соответствии законодательством
              Российской Федерации о персональных данных и определяет как мы обрабатываем, и используем персональные
              данные посетителей (далее - Пользователи)  нашего сайта <a href="/">{{ thisWebsite }}</a>
              (далее - Сайт), пользующихся сервисами, информацией и услугами Компании.
            </p>
            <p>
              Целью настоящей Политики является обеспечение защиты прав и свобод человека и гражданина при обработке его
              персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну,
              защиты информации от несанкционированного доступа и разглашения.
            </p>
            <p>
              Мы с уважением относимся к частной жизни и персональным данным всех посетителей своего Сайта. Все собранные
              во время посещения Сайта компании персональные данные будут использованы только в целях повышения качества
              работы Сайта в целом, либо для обработки Ваших запросов (в том числе для информирования о новых услугах Компании).
            </p>
            <p>
              Сбор и обработка персональных данных посетителей нашего Сайта осуществляется в строгом соответствии с
              законодательством Российской Федерации о защите персональных данных (Федеральный закон Российской Федерации
              от 27 июля 2006 г. № 152-ФЗ «О персональных данных»).
            </p>
            <p>
              Настоящая Политика регулирует любой вид обработки персональных данных и информации личного характера о
              Пользователях Сайта и услуг Компании, в том числе любые действия (операции) или совокупность действий (операций),
              совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными,
              включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение,
              использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление и
              уничтожение персональных данных.
            </p>
            <p><strong>II. Сбор персональных данных</strong></p>
            <p>
              Мы обрабатываем персональные данные с целью выполнить свои обязательства перед нашими Пользователями и
              клиентами по заключенным между нами договорам, соглашениям, а также в связи с использованием Сайта
              Компании и его сервисов.
            </p>
            <p>
              Просмотр Сайта не требует обязательного указания личных сведений и персональных данных, однако, без указания
              своей контактной информации Вы не сможете получать от нас адресованную Вам информацию и оставить заявку на
              оказание услуг, чтобы мы могли Вам перезвонить.
            </p>
            <p>
              Обработка персональных данных наших Пользователей осуществляется только с их согласия.
            </p>
            <p>
              Персональные данные Пользователь предоставляет о себе самостоятельно, своей волей и в своем интересе при
              заполнении специальной формы регистрации на Сайте Компании.
            </p>
            <p>
              Под персональными данными понимается любая информация, относящаяся к прямо или косвенно определенному или
              определяемому физическому лицу (субъекту персональных данных) и которая может быть использована для
              идентификации определенного лица либо связи с ним.
            </p>
            <p><strong>Компания осуществляет обработку следующих персональных данных Пользователей:</strong></p>
            <ul class="entry__list">
              <li>Имя, Фамилия, Отчество;</li>
              <li>Адрес электронной почты;</li>
              <li>Номер телефона.</li>
            </ul>
            <p>
              Персональные данные могут также включать в себя дополнительные данные, которые Пользователи предоставляют по
              запросу Компании, когда это необходимо для того, чтобы мы могли исполнить свои обязательства перед Вами,
              или по своему желанию.
            </p>
            <p>
              Наша цель состоит в том, чтобы хранимые данные о Пользователях были точными и актуальными. Мы будем стараться
              вовремя обновлять информацию и не хранить данные дольше необходимого.
            </p>
            <p>
              Мы не проверяет достоверность персональной информации, предоставляемой Пользователями, однако исходим из того,
              что Пользователь предоставляет достоверную и достаточную персональную информацию и поддерживает эту информацию
              в актуальном состоянии.
            </p>
            <p>
              У Вас есть право знать, какие именно Ваши персональные данные имеет Компания. Вы можете в любой момент
              изменить (обновить, дополнить) предоставленную Вами персональную информацию или её часть самостоятельно на
              Сайте или обратившись с соответствующим запросом в адрес Компании.
            </p>
            <p>Также Вы всегда вправе потребовать исключения Ваших персональных данных из наших баз. В соответствии
              с законом, при получении такого требования мы удалим Ваши данные в 3-дневный срок.
            </p>
            <p><strong>III. Хранение и использование персональных данных</strong></p>
            <p>
              Все персональные данные, которые Вы сообщите в ходе посещения нашего Сайта, будут храниться на специально
              защищенном сервере и обрабатываться с использованием автоматизированных систем, за исключением случаев,
              когда неавтоматизированная обработка персональных данных необходима для исполнения требований
              законодательства или для оказания услуг.
            </p>
            <p>
              Персональные данные и информация о Пользователях будет хранится в течение срока, нужного для выполнения
              целей, для которых они предоставлены, за исключением случаев, когда более длительный период хранения данных
              и информации необходим в соответствии с законодательством.
            </p>
            <p>Компания может использовать Вашу персональную информацию в следующих целях: </p>
            <ul class="entry__list">
              <li>Чтобы идентифицировать Вас как Пользователя нашего Сайта;</li>
              <li>Чтобы мы могли Вам перезвонить и рассказать о наших услугах и как ими воспользоваться;</li>
              <li>Чтобы сделать посещение нашего сайта более удобным;</li>
              <li>Чтобы мы могли улучшить качество Услуг и сервисов, а также разработать что-то новое;</li>
              <li>
                Чтобы мы могли связаться с Вами в процессе оказания услуг и имели возможность направлять Вам уведомления и
                информацию об услугах, а также отвечать на Ваши вопросы;
              </li>
              <li>
                Чтобы мы могли время от времени проводить статистические и аналитические исследования в области рекламы и
                посещаемости Сайта на основе обезличенной персональной информации;</li>
              <li>
                Чтобы мы могли предлагать Вам ту рекламу, которая наиболее соответствует Вашим интересам и потребностям
                (таргетинг рекламы).
              </li>
            </ul>
            <p>
              Сотрудники Компании имеют доступ к Вашим персональным данным исключительно в пределах и объеме,
              необходимых им для выполнения своих должностных функций, связанных с оказанием услуг. При этом все правила
              работы с персональными данными нами неукоснительно соблюдаются.
            </p>
            <p>
              Мы сохраняем конфиденциальность персональных данных Пользователей, за исключением случаев, когда Вы сами
              предоставите информацию о себе для доступа третьих лиц.
            </p>
            <p>
              Мы также заботимся о безопасности данных, чтобы предотвратить несанкционированный доступ к ним других лиц.
            </p>
            <p><strong>IV. Передача и обработка персональных данных</strong></p>
            <p>
              Мы не передаем персональные данные Пользователей каким-либо третьим лицам, за исключением случаев, описанных ниже.
            </p>
            <p>Передача персональных данных Пользователя возможна исключительно в случаях если:</p>
            <ul class="entry__list">
              <li>Пользователь выразил свое согласие на такие действия;</li>
              <li>Передача необходима в рамках оказания Компанией услуг Пользователю;</li>
              <li>
                Передача необходима по запросам уполномоченных органов государственной власти РФ только по основаниям и в
                порядке, установленным законодательством РФ;
              </li>
              <li>
                Передача происходит при реорганизации Компании, продажи или иной передачи бизнеса Компании третьим лицам,
                при этом к приобретателю переходят все обязательства по соблюдению условий настоящей Политики;
              </li>
              <li>
                Пользователя сам дал указание и у Пользователя получено согласие на передачу данных третьим лицам -
                контрагентам Компании. При этом такие контрагенты обязуются обеспечивать конфиденциальность информации
                о Пользователе;
              </li>
            </ul>
            <p>
              Мы заблокируем Ваши персональные данные с момента Вашего обращения, либо обращения уполномоченного органа
              по защите прав субъектов персональных данных, если будет выявлено, что персональные данные являются
              недостоверными или с такими данными осуществляются неправомерные действия.
            </p>
            <p><strong>V. Уничтожение персональных данных</strong></p>
            <p>
              Персональные данные Пользователя уничтожаются Компанией в 3-дневный срок, если Вы отзовете свое согласие на
              обработку персональных данных или просто захотите, чтобы мы их удалили.
            </p>
            <p><strong>VI. Защита персональных данных</strong></p>
            <p>
              Наша Компания предпринимает необходимые и достаточные меры по защите персональных данных Пользователей —
              включая правовые, организационные, административные, технические и физические —в соответствии со ст. 19
              Федерального закона от 27.07.2006 N 152-ФЗ «О персональных данных» в целях обеспечения защиты персональных
              данных Пользователя от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования,
              копирования, распространения, а также от иных неправомерных действий третьих лиц. Безопасность данных
              реализуется программными средствами сетевой защиты.
            </p>
            <p>
              Мы можем гарантировать безопасность Ваших персональных данных только при использовании нашего Сайта.
              Обращаем Ваше внимание, что наша Компания не контролирует и не несет ответственность за сайты третьих лиц,
              на которые Пользователь может перейти по ссылкам в процессе пользования Сайтом или услугами Компании, в том
              числе социальные сети. Будьте внимательны при работе с другими сайтами. Сторонние сайты могут собирать и
              запрашивать у Пользователя иную персональную информацию, а также использовать ее в собственных целях.
              Действия сторонних сайтов должны регулироваться правилами работы с персональными данными таких сайтов.
            </p>
            <p><strong>VII. Сбор и использование информации, не являющейся персональной</strong></p>
            <p>
              Во время просмотра нашего Сайта, чтения текстов на его страницах и загрузки иной информации, происходит
              автоматическая регистрация некоторых данных о компьютере, с которого Вы просматриваете Сайт.
            </p>
            <p>
              Эта информация ничего не говорит о Вас лично и не является персональной (то есть ее невозможно
              ассоциировать с определенным лицом). Она всего лишь позволяет нам подсчитать число посетителей Сайта,
              посещаемость отдельных страниц, а также выяснить типы используемых ими компьютеров и программного обеспечения.
              Благодаря этому мы можем постоянно улучшать сайт и делать его более удобным и полезным для Вас.
            </p>
            <p>Такую информацию помогают собирать файлы cookie, об использовании которых будет рассказано далее.</p>
            <p><strong>При просмотре сайта мы собираем следующие данные:</strong></p>
            <ul class="entry__list">
              <li>Дату и время посещения Сайта;</li>
              <li>Число посещенных страниц, их названия, а также длительность просмотра;</li>
              <li>
                Имя интернет-домена (чаще всего это имя компании, предоставляющей услуги выхода в Интернет,
                или организации, в которой Вы работаете) и IP-адрес, присвоенный компьютеру для выхода в Интернет;
              </li>
              <li>
                Тип браузера (например, Microsoft Internet Explorer, Google Chrome, Opera, Mozilla Firefox) и
                операционной системы (например, Windows 7 или Mac OS);
              </li>
              <li>
                Тип устройства, с которого Вы зашли на Сайт (компьютер, планшетный компьютер, мобильное устройство или
                иное) и его модель (для мобильных устройств);
              </li>
              <li>
                Сайт, с которого Вы осуществили переход на наш сайт (например, Yandex, если Вы нашли наш сайт с
                помощью поисковой машины).
              </li>
            </ul>
            <p><strong>ИСПОЛЬЗОВАНИЕ ФАЙЛОВ COOKIE</strong></p>
            <p>
              В Компании используются такие технологии, как файлы cookie и пиксельные метки, чтобы облегчить использование
              нашего веб-сайта и предоставлять Вам наиболее подходящий контент. Мы постарались подробно объяснить,
              что такое файлы cookie, для чего они используются на веб-сайте Компании и как в случае необходимости управлять
              ими или удалять их.
            </p>
            <p><strong>Что такое файл cookie?</strong></p>
            <p>
              Практически все веб-сайты используют технологию cookie. Cookie — это небольшой файл, используемый для
              сохранения настроек просмотра интернет-страниц. В ходе первого посещения веб-сайта этот файл загружается с
              помощью браузера. При следующем посещении этого веб-сайта с того же устройства браузер может проверить
              наличие связанного файла cookie (т.е. содержащего имя сайта) и использовать данные этого файла cookie для их
              отправки обратно на сайт. Это позволяет сайту определить, что с этого браузера сайт уже посещался, и в
              некоторых случаях изменять отображаемый контент.
            </p>
            <p>
              На нашем сайте мы используем как собственные файлы cookie, так и сторонние аналитические инструменты для
              сбора данных, такие как сервисы: Google Analytics, Яндекс.Метрика, TEXTUS.Pro, AdHands, Livetex, СоMagic.
              Эти сервисы помогают собирать информацию об использовании нашего Сайта, а также могут собирать дополнительную
              информацию (о поле, возрасте и предпочтениях пользователей) и делают это на общей или анонимной основе,
              которая включает всех посетителей сайта.
            </p>
            <p>Мы используем файлы cookie, которые устанавливаются из многих доменов. Примеры таких доменов перечислены ниже.</p>
            <ul class="entry__list">
              <li>adhands.ru </li>
              <li>sedu.adhands.ru </li>
              <li>livetex.ru </li>
              <li>widgets.livetex.ru </li>
              <li>mc.yandex.ru </li>
              <li>webvisor.org </li>
              <li>mc.webvisor.org </li>
              <li>google.com </li>
              <li>vk.com </li>
              <li>facebook.com </li>
              <li>doubleclick.net </li>
            </ul>
            <p><strong>Преимущества файлов cookie</strong></p>
            <p>
              Некоторые файлы cookie являются крайне полезными, потому что могут облегчить последующее использование
              веб-сайта, на который Вы уже заходили несколько раз. При условии использования Вами того же устройства и
              веб-браузера, что и в ходе предыдущего посещения, сайт может запоминать Ваши предпочтения, сообщать нам о том,
              как именно Вы используете наш Сайт, а также отображать контент, наиболее соответствующий Вашим интересам и
              потребностям.
              <strong>Никакие из файлов cookie, используемых на нашем Сайте, не собирают личную информацию о пользователях.</strong>
            </p>
            <p><strong>Категории файлов cookie</strong></p>
            <p>Мы используем следующие категории файлов «cookie», на нашем Сайте:</p>
            <h3><strong><em>Категория 1</em></strong> — строго необходимые файлы «cookie».</h3>
            <p>Эти файлы «cookie» необходимы для просмотра веб-сайта и использования его функций. </p>
            <h3><strong><em>Категория 2</em></strong> — эксплуатационные файлы «cookie».</h3>
            <p>
              Эти файлы «cookie» собирают информацию об использовании Сайта, например, о наиболее часто посещаемых
              страницах. Такие данные могут быть использованы для оптимизации нашего Сайта и упрощения навигации.
              Эти файлы «cookie» также используются нашими партнерами для того, чтобы определить, перешли ли вы на наш
              Сайт с сайта партнера, воспользовались ли вы нашими услугами в результате посещения веб-сайта. Эти файлы
              «cookie» не используются для сбора личной информации. Вся информация, собранная с их помощью, предназначена
              для статистических целей и остается анонимной.
            </p>
            <h3><strong><em>Категория 3</em></strong> — функциональные файлы «cookie».</h3>
            <p>
              Эти файлы «cookie» позволяют нашему Сайту запомнить сделанный вами выбор при просмотре сайта. Эти файлы
              также могут использоваться для запоминания таких настроек, как размер и шрифт текста, а также других
              настраиваемых параметров сайта. Эти файлы также могут использоваться для отслеживания рекомендуемых
              продуктов и видеоматериалов во избежание повторения. Информация, предоставляемая такими файлами «cookie»,
              не позволяет вас идентифицировать.
            </p>
            <p><strong>Контроль и удаление файлов cookie</strong></p>
            <p>
              Если Вы хотите изменить способ использования браузером файлов cookie, включая блокирование или удаление
              файлов cookie с веб-сайтов, Вы можете сделать это в любой момент с помощью настроек Вашего браузера.
              При отключении, удалении или отказе от получения файлов cookie некоторые функции или возможности Сайта
              могут оказаться недоступными.
            </p>
            <p>
              Для управления файлами cookie большинство браузеров позволяют Вам либо принимать или отклонять все файлы
              cookie, либо принимать только некоторые типы файлов cookie, либо спрашивают Вас о необходимом действии
              каждый раз, когда веб-сайт хочет сохранить файл cookie. Кроме того, сохраненные на Вашем устройстве файлы
              cookie очень просто удалить с помощью браузера.
            </p>
            <p>
              Процесс контроля и удаления файлов cookie зависит от используемого Вами веб-браузера. Чтобы узнать, как
              это делать с конкретным браузером, используйте встроенную справку браузера или посетите веб-сайт
              <a href="https://www.aboutcookies.org/" target="_blank">https://www.aboutcookies.org</a>,
              на котором приводятся пошаговые объяснения по контролю и удалению файлов cookie для большинства браузеров,
              а также более подробно ознакомиться с информацией о файлах cookie Вы сможете здесь:
              <a href="https://www.google.ru/policies/technologies/types/">https://www.google.ru/policies/technologies/types/</a>,
              <a href="https://yandex.ru/support/browser-classic/personal-data-protection/cookies.xml?lang=ru">https://yandex.ru/support/browser-classic/personal-data-protection/cookies.xml?lang=ru</a>,
              <a href="http://www.aboutads.info/choices/">www.aboutads.info/choices/</a>,
              <a href="http://www.youronlinechoices.com/">www.youronlinechoices.com</a>,
              <a href="http://www.appnexus.com/platform-policy#choices">http://www.appnexus.com/platform-policy#choices</a>.
            </p>
            <p><strong>VII</strong><strong>I</strong><strong>. Обращения пользователей</strong></p>
            <p>
              Вы можете направлять нам запросы, связанные с использованием персональных данных, а также заявления об
              отзыве согласия на обработку персональных данных в письменной форме по адресу: 690089, г. Владивосток, ул.
              Тухачевского, д. 48а.
            </p>
            <p>
              Ваш запрос должен содержать данные Вашего паспорта или другого документа, удостоверяющего личность, и
              информацию, подтверждающую, что наша Компания обрабатывает Ваши персональные данные (например, ссылку на
              заполнение формы на Сайте), и Вашу подпись или подпись Вашего представителя. Запрос может быть также
              направлен в форме электронного документа и подписан электронной подписью.
            </p>
            <p>Компания обязуется рассмотреть и направить ответ на Ваш запрос в течение 30 дней с момента поступления обращения.</p>
            <p><strong>IX</strong><strong>. Заключительные положения</strong></p>
            <p>
              Во всем остальном, что не отражено в настоящей Политике, Компания обязуется руководствоваться нормами и
              положениями Федерального закона от 27.07.2006 N 152-ФЗ «О персональных данных».
            </p>
            <p>
              Вы соглашаетесь с положениями настоящей Политики, если при посещении сайта Компании, предоставили свои
              персональные данные и информацию путем заполнения формы на Сайте.
            </p>
            <p>
              Компания оставляет за собой право вносить любые изменения в Политику в любое время по своему усмотрению с
              целью дальнейшего совершенствования системы защиты от несанкционированного доступа к сообщаемым Пользователями
              персональным данным. В случае внесения изменений в Политику, политика публикуется нами в общем доступе на
              Сайте Компании. Действие настоящей Политики не распространяется на совершаемые действия на интернет-ресурсах
              третьих лиц.
            </p>
            <p><strong>X</strong><strong>. Обратная связь</strong></p>
            <p>
              Все предложения и вопросы, связанные с настоящей Политикой и/или функционированием Сайта следует направлять
              в ООО «Юник Продактс» по адресу электронной почты vladivostok@uniqom.ru или по адресу: 690089, г. Владивосток,
              ул. Тухачевского, д. 48а.
            </p>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  computed: {
    thisWebsite() {
      return location.hostname
    }
  },
  metaInfo: {
    title: 'Политика защиты персональных данных',
    titleTemplate: `%s - ${process.env.VUE_APP_NAME}`,
  },
}
</script>