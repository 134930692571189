import Vue from 'vue'
import VueRouter from 'vue-router'

// IMPORT PAGES
import Main from './views/Main.vue';
import About from './views/About.vue';
import Partners from './views/Partners.vue';
import Catalog from './views/Catalog.vue';
import Articles from './views/Articles.vue';
import SingleArticle from './views/SingleArticle.vue';
import Map from './views/Map.vue';
import Consent from './views/Consent.vue';
import Policy from './views/Policy.vue';
import Certificates from './views/Certificates.vue';
import Choose from './views/Choose.vue';
import Certs from './views/Certs.vue';
import CertsCheck from './views/CertsCheck.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'main',
    component: Main
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/partners',
    name: 'partners',
    component: Partners
  },
  {
    path: '/catalog/:category?',
    name: 'catalog',
    component: Catalog,
  },
  {
    path: '/catalog/product/:product?',
    name: 'product',
    component: Catalog,
  },
  {
    path: '/articles',
    name: 'articles',
    component: Articles
  },
  {
    path: '/articles/:id',
    name: 'articles:id',
    component: SingleArticle
  },
  {
    path: '/map',
    name: 'map',
    component: Map,
  },
  {
    path: '/consent',
    name: 'consent',
    component: Consent,
  },
  {
    path: '/policy',
    name: 'policy',
    component: Policy,
  },
  {
    path: '/certificates',
    name: 'certificates',
    component: Certificates,
  },
  {
    path: '/choose',
    name: 'choose',
    component: Choose,
  },
  {
    path: '/actions',
    name: 'actions',
    component: Articles
  },
  {
    path: '/actions/:id',
    name: 'actions:id',
    component: SingleArticle
  },
  {
    path: '/certs',
    name: 'certs',
    component: Certs
  },
  {
    path: '/certs-check',
    name: 'certs-check',
    component: CertsCheck
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
