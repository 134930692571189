<template>
    <div class="consent">
      <div class="container">
        <div class="container__liner">
          <div id="navigation">
            <div class="breadcrumbs">
              <a itemprop="itemListElement" href="/" class="breadcrumbs-item">Главная</a>
              <span class="breadcrumbs-item">Согласие на обработку персональных данных</span>
            </div>
          </div>

          <div class="title">
            <h1 id="pagetitle" class="entry">
              <span>Согласие на обработку персональных данных</span>
            </h1>
          </div>
        </div>
      </div>

      <div class="container">
        <div class="container__liner">
          <div class="entry">
            <div class="entry__content">
              <p class="entry__strong">
                Я, предоставляя свои персональные данные на сайте
                <a href="/">{{ thisWebsite }}</a>:
              </p>

              <p>
                1.1. своей волей и в своем интересе выражаю свое бессрочное согласие на обработку моих персональных
                данных ООО «» (ИНН 2543107060, адрес местонахождения: 690089, г. Владивосток, ул. Тухачевского, д. 48а)
                в соответствии с ФЗ «О персональных данных» № 152 — ФЗ от 27 июля 2006 г.
                и <a href="/policy">Политикой защиты персональных данных</a>
                ООО «Юник Продактс», а именно на любое действие (операции) или совокупность действий, совершаемых с
                использованием средств автоматизации или без использования таких средств с указанными персональными
                данными, включая их сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение),
                извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование,
                удаление, уничтожение, на весь срок до отзыва настоящего согласия.
              </p>
              <p>
                1.2. уведомлен о том, что данное выше согласие может быть отозвано мной в любой момент посредством
                направления письменного отказа от обработки персональных данных в ООО «Юник Продактс» по адресу:
                690089, г. Владивосток, ул. Тухачевского, д. 48а.
              </p>
              <p>
                1.3. согласен с использованием имени, фамилии, отчества номера телефона, адреса электронной почты,
                принадлежащего мне и указанного в форме обратной связи, для связи с мной, в том числе по телефону, для
                получения информационных материалов, в том числе рассылок, от ООО «Юник Продактс» (ИНН 2543107060,
                адрес: 690089, г. Владивосток, ул. Тухачевского, д. 48а).
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>
</template>

<script>

export default {
  computed: {
    thisWebsite() {
      return location.hostname
    }
  },
  metaInfo: {
    title: 'Согласие на обработку персональных данных',
    titleTemplate: `%s - ${process.env.VUE_APP_NAME}`,
  },
}
</script>