<template>
  <div class="home">
    <select-product />
    <widget-catalog />
    <widget-brands />
    <widget-about />
    <widget-trust />
    <widget-articles />
    <widget-map />
  </div>
</template>

<script>
import SelectProduct from '../components/SelectProduct'
import WidgetCatalog from '../components/WidgetCatalog'
import WidgetBrands from '../components/WidgetBrands'
import WidgetAbout from '../components/WidgetAbout'
import WidgetTrust from '../components/WidgetTrust'
import WidgetArticles from '../components/WidgetArticles'
import WidgetMap from '../components/WidgetMap'

export default {
  name: 'home',
  components: {
    SelectProduct,
    WidgetCatalog,
    WidgetBrands,
    WidgetAbout,
    WidgetTrust,
    WidgetArticles,
    WidgetMap
  },
  metaInfo: {
    title: process.env.VUE_APP_NAME,
    titleTemplate: `%s - Масла и технические жидкости для японских автомобилей`,
  },
}
</script>