<template>
  <footer class="footer">
    <div class="sidenotes">
      <div class="sidenotes__container container">
        <div class="sidenotes__head">
          <div class="sidenotes__logo">
            <a href="https://uniqom.ru/" target="_blank">
              <img src="~@/assets/img/unicom-logo.png" class="icon icon-unicom" alt="logo"/>
            </a>
          </div>
          <div class="sidenotes__description">
            <b>Официальный дистрибьютор</b><br>
            <b>продукции TCL в России</b>
          </div>
        </div>
        <div v-if="menu" class="sidenotes__menu">
          <div v-for="(link, index) in menu" :key="index" class="sidenotes__item">
            <a class="sidenotes__ref" :href="link.href">
              {{ link.title }}
            </a>
          </div>
        </div>
        <div class="sidenotes__relations">
          <button class="sidenotes__ask hollow" @click="feedbackOpen">
            Задать вопрос
          </button>
          <a href="https://tanikawayuka.co.jp/eng/" target="_blank" class="sidenotes__ask white">
            <span class="white__before"></span>
            Переход на сайт <br> производителя TCL
          </a>
        </div>
      </div>
    </div>

    <div class="authors">
      <div class="authors__container container">
						<span class="authors__owners">
							© {{ thisYear }} ЮНИКОМ
            </span>
        <!-- <a href="https://www.realweb.ru/ " target="_blank" class="authors__creators">
          <svg class="icon icon-realweb">
            <use xlink:href="@/assets/img/elem/symbols.svg#icon-realweb"></use>
          </svg>
        </a> -->
      </div>
    </div>
  </footer>
</template>

<script>
import MenuJson from '@/assets/data/menu.json';

export default {
  data() {
    return {
      menu: MenuJson
    }
  },
  computed: {
    thisYear: function () {
      return (new Date()).getFullYear();
    },
    storeProm: function() {
      return this.$store.state.prom
    },
  },
  watch: {
    storeProm(prom) {
      if (!prom) {
        this.menu = MenuJson.filter(function(item) { return item.title !== 'Акции' })
      }
    },
  },
  methods: {
    feedbackOpen(event) {
      event.preventDefault();
      this.$emit('feedback', { enabled: true });
    },
  },
}
</script>