<template>
  <form>
    <div class="variants variants_offset">
      <div class="variants__container container">
        <div class="variants__inside">
          <div class="variants__heading">
            Выберите продукцию TCL по модели автомобиля
          </div>
          <widget-select />
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import WidgetSelect from './WidgetSelect'

export default {
  components: {
    WidgetSelect
  },
  data() {
    return {
      
    }
  },
  methods: {

  }
}
</script>