<template>
  <div
      class="ui-selectmenu-block ui-front"
      :class="{'ui-selectmenu-open': open}"
  >
    <div class="ui-selectmenu-menu">
      <ul class="ui-menu minimal-select-menu">
        <li v-if="state" class="ui-menu-item">
          <div class="ui-menu-item-wrapper ui-state-active">
            {{ state }}
          </div>
        </li>
        <li
            class="ui-menu-item no-results"
            v-for="(item, index) in data"
            :key="index"
            @click.stop="selected(item)"
        >
          <div class="ui-menu-item-wrapper" :data-id="item.id">
            {{ item.name || item.title }}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    id: null,
    open: null,
    data: null,
    state: null,
    queryItem: null,
  },
  methods: {
    selected(item) {
      this.$emit('selected', { item });
      document.body.removeEventListener('click', this.handleClick, false);
      this.$emit('open', { enabled: false });
    },
    handleClick(event) {
      if (!event.target.closest(`[select-item="${this.id}"]`)) {
        document.body.removeEventListener('click', this.handleClick, false);
        this.$emit('open', { enabled: false });
      }
    },
    selectItem(data) {
      data.forEach(item => {
        if (item.id == this.queryItem) this.selected(item)
      });
    }
  },
  watch: {
    open: function(value) {
      if (value) {
        document.body.addEventListener('click', this.handleClick, false);
      }
    },
    data: function(value) {
      if (this.queryItem && value) this.selectItem(value)
    }
  },
}
</script>