<template>
  <div id="brands" class="brands">
    <div class="brands__container container">
      <div class="brands__inside">
        <div class="brands__title title">
          <h2>
            <span>Безупречная совместимость продукции TCL <br class="uk-visible@s"> с автомобилями японских производителей</span>
          </h2>
        </div>
        <div class="brands__content">
          <div class="brands__heading heading">
            Выберите автомобиль <br> и начните подбор <br> прямо сейчас
          </div>
          <div class="brands__items">
            <div class="brands__inner" v-if="brands">


              <div
                  v-for="(item, index) in brands.data"
                  class="brands__item"
                  :class="{'brands__item_open' : brand_open === item.id}"
                  :brand-item="item.id"
                  :key="index"
              >
                <div class="brand__container" @click="getInitDataModels(item)">
                  <svg class="icon icon-mits">
                    <use :href="`${require(`@/assets/img/elem/symbols.svg`)}#${item.icon}`"></use>
                  </svg>
                </div>
                <div class="brands__dropdown">
                  <form class="brands__dropdown-inner" method="post">
                    <input type="hidden" name="mark" :value="item.id">
                    <div class="brands__things">
                      <div class="brands__thing">
                        <span
                            class="ui-selectmenu-button ui-button ui-widget compact-select"
                            @click="list_open = 'model'"
                            select-item="product"
                        >
                          <span class="ui-selectmenu-icon ui-icon"></span>
                          <span class="ui-selectmenu-text uk-text-truncate">
                             {{ select_model == null ? 'Модель автомобиля' : select_model.title }}
                          </span>
                          <select-item
                              id="product"
                              state="Модель автомобиля"
                              :open="list_open === 'model'"
                              :data="models"
                              @selected="select_model = $event.item"
                              @open="list_open = $event.enabled"
                          />
                        </span>
                      </div>
                      <div class="brands__thing" select-item="product">
                        <span
                            class="ui-selectmenu-button ui-button ui-widget compact-select"
                            @click="list_open = 'set'"
                            select-item="product"
                        >
                          <span class="ui-selectmenu-icon ui-icon"></span>
                          <span class="ui-selectmenu-text uk-text-truncate">
                             {{ select_set == null ? 'Комплектация' : select_set.title }}
                          </span>
                          <select-item
                               id="product"
                               state="Комплектация"
                               :open="list_open === 'set'"
                               :data="set"
                               @selected="select_set = $event.item"
                               @open="list_open = $event.enabled"
                          />
                        </span>
                      </div>
                    </div>
                    <div class="brands__confirm">
                      <button
                          type="button"
                          class="green"
                          @click="testSelection"
                      >
                        Подобрать товар
                      </button>
                    </div>

                    <div
                        :class="{'select-notice--show' : errorMessage}"
                        class="select-notice"
                    >
                      <span v-html="errorMessage"></span>
                    </div>
                  </form>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectItem from './SelectItem'
import WidgetBrandsJson from '@/assets/data/widget_brands.json';
import utils from '../utils'

export default {
  components: {
    SelectItem
  },
  data() {
    return {
      brands: WidgetBrandsJson,
      brand_open: false,
      list_open: false,
      models: null,
      set: null,
      select_model: null,
      select_set: null,
      select_mark_title: null,
      errorMessage: null
    }
  },
  computed: {
    buildQuery() {
      let query = ''
      if (this.select_model) {
        query += `?mark=${this.select_model.mark_id}`
        query += `&model=${this.select_model.id}`
      }
      if (this.select_set) query += `&set=${this.select_set.id}`
      return query
    }
  },
  methods: {
    handleClick(event) {
      if (!event.target.closest(`[brand-item='${this.brand_open}']`)) {
        this.brand_open = false;
        document.body.removeEventListener('click', this.handleClick, false);
      }
    },
    testSelection(ref = true) {
      this.errorMessage = null
      let messages = []

      if (!this.select_model) messages.push('<b>модель авто</b>')
      if (!this.select_set) messages.push('<b>комплектация авто</b>')

      if (messages.length) {
        this.errorMessage = 'Не выбрана ' + messages.join(', ');
        return false
      }

      if (ref) {
        this.$router.push(`/choose${this.buildQuery}`)
      }
    },
    async getInitDataModels(item) {
      const { fetchData } = utils()

      if (item && this.brand_open !== item.id) {
        this.select_set = null
        this.select_model = null
        this.brand_open = item.id

        let target = `selection/models?mark_title=${item.title}`
        let response = await fetchData('get', target)
        if (response) this.models = response.data
      }
    },
    async getInitDataModifics() {
      const { fetchData } = utils()

      if (this.select_model) {
        let target = `selection/modifics?model=${this.select_model.id}`

        let response = await fetchData('get', target)
        if (response) this.set = response.data
      }
    },
  },
  watch: {
    brand_open: function(value) {
      this.errorMessage = null
      if (value) {
        document.body.addEventListener('click', this.handleClick, false)
      }
    },
    select_model: function() {
      this.select_set = null
      if (this.errorMessage) this.testSelection(false)
      this.getInitDataModifics()
    },
    select_set: function() {
      if (this.errorMessage) this.testSelection(false)
    }
  },
}
</script>