<template>
   <span
       class="ui-selectmenu-button ui-button minimal-select"
       v-if="cities"
       @click="list_open = !list_open"
       select-item="city"
   >
     <span class="ui-selectmenu-icon ui-icon"></span>
     <span class="ui-selectmenu-text" v-if="select_city">
       {{ select_city.name }}
     </span>
     <select-item
         id="city"
         :open="list_open"
         :data="cities"
         @selected="select_city = $event.item"
         @open="list_open = $event.enabled"
     />
   </span>
</template>

<script>
import axios from "axios";
import SelectItem from './SelectItem'
import utils from '../utils'

export default {
  components: {
    SelectItem
  },
  data() {
    return {
      list_open: false,
      select_city: null,
      cities: null,
      api_cities: 'cities/tcl',
    }
  },
  computed: {
    detectRobot() {
      // eslint-disable-next-line
      const robots = new RegExp([
        /bot/,/spider/,/crawl/,
        /APIs-Google/,/AdsBot/,/Googlebot/,/Googlebot-Mobile/,
        /mediapartners/,/Google Favicon/,/Googlebot-Image/,
        /FeedFetcher/,/Google-Read-Aloud/,
        /DuplexWeb-Google/,/googleweblight/,
        /bing/,/yandex/,/baidu/,/duckduck/,/yahoo/,
        /ecosia/,/ia_archiver/,
        /facebook/,/instagram/,/pinterest/,/reddit/,
        /slack/,/twitter/,/whatsapp/,/youtube/,
        /semrush/,
      ].map((r) => r.source).join("|"),"i");

      return robots.test(navigator.userAgent);
    }
  },
  methods: {
    async getCities() {
      const { fetchData } = utils()
      let response = await fetchData('get', this.api_cities)
      if (response) {

        this.cities = response
        this.$store.commit('SET_CITIES', this.cities)

        const lsBlockTime = this.$localStorage.get('BLOCK_TIME')
        const lsSelectCity = this.$localStorage.get('SELECT_CITY')

        if (lsBlockTime && lsSelectCity) {
          if (+new Date() >= parseInt(lsBlockTime)) {
            this.$localStorage.remove('BLOCK_TIME')
            this.$localStorage.remove('LOCATION')
            this.$localStorage.remove('SELECT_CITY')
          } else {
            this.select_city = JSON.parse(lsSelectCity)
            return 1
          }
        }

        await this.getLocationByClientIP()

        if (this.select_city === null) {
          this.select_city = response[0]
        }
      }
    },
    async getCoordsLocation() {
      if (navigator.geolocation) {
        let lat = null;
        let lng = null;
        try {
          const getCoords = async () => {
            const position = await new Promise((resolve, reject) => {
              navigator.geolocation.getCurrentPosition(resolve, reject)
            });
            return position
          };
          const position = await getCoords();
          if (position) {
            lat = position.coords.latitude
            lng = position.coords.longitude
            await this.getLocationByCoords(lat, lng)
          } else {
            throw new Error('Failed to determine location')
          }
        } catch (e) {
          console.log('Failed Error:', e)
        }
      } else {
        console.log('Geolocation service is not supported by this browser')
      }
    },
    async getLocationByCoords(lat, lng) {
      const target = `apikey=${process.env.VUE_APP_YANDEX_API_KEY}&geocode=${lng},${lat}`;
      let response;
      await axios
          .get(`${process.env.VUE_APP_YANDEX_GEO_URL}&${target}`)
          .then((res) => {
            response = res.data;
          });
      this.getCityByYandexGeoAPI(response)
    },
    getCityByYandexGeoAPI(api) {
      let apiData = null;
      const BreakException = {};
      try {
        apiData = api.response.GeoObjectCollection.featureMember;
        this.cities.forEach((city) => {
          apiData.forEach((item) => {
            if (city.name === item.GeoObject.name) {
              this.select_city = city
              throw BreakException
            }
          });
        });
      } catch (e) {
        if (e !== BreakException) console.log(e);
      }
    },
    async getLocationByClientIP() {
      let IP = null

      if (this.detectRobot === false) {
        await axios
            .get(process.env.VUE_APP_CLIENT_IP)
            .then((res) => IP = res.data);
      }

      if (IP) {
        let target = `${process.env.VUE_APP_GEO_URL}/${process.env.VUE_APP_GEU_KEY}/json/${IP}`
        let response = null
        await axios
            .get(target)
            .then((res) => response = res.data);

        if (response && response.city) {
          let location = {
            lat: response.city.lat,
            lon: response.city.lon,
            name: response.city.name_ru
          }

          this.$localStorage.set('LOCATION', JSON.stringify(location))
          this.getCityByName(location.name)
        }
      }
    },
    getCityByName(name) {
      this.select_city = this.cities.find(city => {
        return city.name === name
      })
    },
    setBlockTime: function () {
      let blockTime = new Date()
      blockTime.setDate(blockTime.getDate() + 4)
      this.$localStorage.set('BLOCK_TIME', +blockTime)
    },
  },
  watch: {
    select_city: function(value) {
      this.$store.commit('SELECT_CITY', value)
      this.$localStorage.set('SELECT_CITY', JSON.stringify(value))
      this.setBlockTime()
    }
  },
  beforeMount() {
    this.getCities()
  }
}
</script>
