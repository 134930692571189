<template>
  <div v-if="data" class="articles single-article">
    <div class="container">
      <div class="container__liner">
        <div id="navigation">
          <div class="breadcrumbs">
            <a href="/" class="breadcrumbs-item">Главная</a>
            <a :href="href" class="breadcrumbs-item">{{ (this.data.type === 3)?'Акции':'Новости и статьи' }}</a>
            <a v-if="typeName" :href="`/articles?type=${data.type}`" class="breadcrumbs-item"> {{ typeName }}</a>
            <span class="breadcrumbs-item">{{ data.title }}</span>
          </div>
        </div>
        <div class="title">
          <h1 id="pagetitle">
            <span>{{ data.title }}</span>
          </h1>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="container__liner">
        <div class="entry">
          <div class="entry__date">
            {{ dateArticle }}
          </div>
          <div v-html="data.content" class="entry__content">
            <!-- Article Content -->

          </div>
          <div class="entry__continue">
            <div v-if="data.tags.length" class="entry__tags">
              <div class="entry__label">
                Метки {{ relatedName() }}
              </div>
              <a
                  v-for="(tag, ind) in data.tags"
                  :key="ind"
                  :href="`${href}?tag=${tag.id}`"
                  class="entry__tag inviting">
                {{ tag.title }}
              </a>
            </div>

            <div v-if="showRelated" class="entry__items">
              <div class="knowledge">
                <div class="title knowledge__title">
                  <h2>{{ (this.data.type === 3)?'Другие акции':'Читайте также' }}</h2>
                </div>

                <div class="knowledge__items" v-if="data.related.rand.length > 0">
                  <article-item
                      v-for="(event, index) in data.related.rand"
                      :key="index"
                      :init-data="event"
                  />
                </div>

                <div class="explore" v-if="data.related.prev !== data.id">
                  <div class="explore__navigation explore__steps">
                    <a :href="`${href}/${data.related.prev}`" class="explore__step">
                      <span class="explore__prev arrow-left">
                        <svg class="icon icon-left">
                          <use xlink:href="@/assets/img/elem//symbols.svg#icon-left"></use>
                        </svg>
                      </span>
                      Предыдущая {{ relatedName(true) }}
                    </a>
                    <a :href="`${href}/${data.related.next}`" class="explore__step">
                      Следующая {{ relatedName(true) }}
                      <span class="explore__next arrow-right">
                        <svg class="icon icon-left">
                          <use xlink:href="@/assets/img/elem/symbols.svg#icon-right"></use>
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
import ArticleItem from '../components/Article.vue'
import utils from '../utils';

export default {
  components: {
    ArticleItem
  },
  data() {
    return {
      data: null,
    }
  },
  computed: {
    dateArticle() {
      let dateInformation = this.data.date;
      return this.$moment(dateInformation.date).format('DD MMMM YYYY')
    },
    typeName() {
      switch(this.data.type) {
        case(1) : return 'Новости и события';
        case(2) : return 'Экспетрные статьи'
      }
      return false
    },
    href() {
      if(this.$route.path.includes('articles')) return '/articles'
      else if(this.$route.path.includes('actions')) return '/actions'
      return '/'
    },
    showRelated() {
      if (this.data.related) {
        if (this.data.related.rand.length > 0 || this.data.related.prev !== this.data.id) return true
      }
      return false
    },
  },
  methods: {
    async getInitDataArticle() {
      const { fetchData } = utils()
      let target = `news?id=${this.$route.params.id}&related=true`
      this.data = await fetchData('get', target)
    },
    relatedName(arrows = false) {
      switch(this.data.type) {
        case(1) : return arrows?'новость':'новости:';
        case(2) : return arrows?'статья':'статьи:';
        case(3) : return arrows?'акция':'акции:';
      }
      return arrows?'страница':'страницы:';
    },
  },
  beforeMount() {
    this.getInitDataArticle()
  },
  metaInfo: {
    title: 'Новости и статьи',
    titleTemplate: `%s - ${process.env.VUE_APP_NAME}`,
  },
  watch: {
    data(data) {
      if (data.title) {
        document.title = `${data.title} - ${process.env.VUE_APP_NAME}`;
      }
    },
  }
}
</script>