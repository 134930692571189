<template>
  <div
      class="container container_detail"
      v-if="item"
      v-scroll="handleScroll"
      v-resize="handleResize"
  >
    <div class="container__liner">
      <div class="product__package">
        <div class="product__sidebar">
          <div class="product__preview">
            <div class="draggable">
              <div class="product__preview-item">
                <a
                    class="product__preview-magnify js-lightbox-item"
                    href="#modal-gallery-images-single"
                    uk-toggle
                >
                  <img
                      :src="getImage('products',  currentImg)"
                      class="product__preview-face"
                      :alt="currentImg"
                  />
                </a>
              </div>
            </div>
          </div>
          <div class="product__conveyer">
            <div class="slick-list">
              <div class="slick-track">
                <template v-for="(article, index) in item.articles">
                  <template v-for="(file, ind) in article.files">
                    <div
                        v-show="showImages(article)"
                        :key="`article-${index}-${ind}`"
                        class="product__conveyer-item"
                        :class="{ 'product__conveyer-item_active': concat_path(article, file) === currentImg}"
                        @click="currentImg = concat_path(article, file); indexModal = ind"
                        style="width: 129px;"
                    >
                      <div class="product__conveyer-frame">
                        <img
                            :src="getImage('products', concat_path(article, file))"
                            class="product__conveyer-face"
                            :alt="file"
                        />
                      </div>
                    </div>
                  </template>
                </template>
              </div>
            </div>
          </div>
        </div>

        <!----
             Описание товара
        ---->

        <div class="product__content">
          <div class="product__types">
            <div
                class="product__types-column"
                v-for="(article, index) in item.articles"
                :key="index"
            >
              <div class="product__types-item">
                <label class="creative-ui">
                  <input
                      type="radio"
                      name="product-types"
                      class="creative-ui__native"
                      :checked="index === 0"
                      @click="onChangeCurrentArticle(article)">
                  <div class="creative-ui__presence">
                  </div>
                  <div class="creative-ui__text">
                    <strong>{{ +article.volume }} л</strong> (Артикул {{article.title}})
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div class="product__section">
            <div class="product__section-header">
              Описание товара
            </div>
            <div class="product__section-text">
              <p v-html="item.text"></p>
            </div>

            <template v-if="item.property">
              <div class="product__section-header">
                Свойства товара
              </div>
              <div class="product__section-text">
                <p v-html="item.property"></p>
              </div>
            </template>
          </div>
          <div class="product__section" v-if="item.spec.length">
            <div class="product__section-header">
              Технические характеристики
            </div>
            <table class="stripes">
              <tbody>
              <tr v-for="(row, index) in item.spec" :key="index">
                <td>{{row.title}}</td>
                <td>{{row.value}}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="product__section" v-if="item.cert.length">
            <div class="product__section-header">
              Сертификаты качества
            </div>
            <div class="product__sertificates">
              <a
                  v-for="(cert, index) in item.cert"
                  :key="index"
                  class="product__sertificates-item"
                  href="#modal-gallery-images-cert"
                  uk-toggle
                  @click="indexModal = index"
              >
                <span class="file-pdf">
                   <pdf
                       :source="getImage('certificates', cert.filename)"
                       :page="1"
                   />
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="accordion js-acc product__reviews">
        <div id="reviews_container" class="js-acc-item">
          <a
            href="javascript:void(0)"
            class="accordion__head accordion__head_switcher js-acc-head"
            @click="showReviews()"
          >
            <span class="product__reviews-opened">Скрыть отзывы покупателей</span>
            <span class="product__reviews-closed">Показать отзывы покупателей</span>
          </a>
          <div class="accordion__content js-acc-body" style="height: 0px; overflow: hidden;">
            <div class="reviews">
              <div class="reviews__main">
                <div id="reviews_list">
                  <div class="reviews__heads">
                    <div class="reviews__label">Сортировать:</div>
                    <a
                      href="javascript:void(0)"
                      class="reviews__head"
                      @click="sortBy = 'date'"
                      :class="{ reviews__head_active: sortBy === 'date' }"
                    >По дате</a>
                    <a
                      href="javascript:void(0)"
                      class="reviews__head"
                      @click="sortBy = 'stars'"
                      :class="{ reviews__head_active: sortBy === 'stars' }"
                    >По количеству звезд</a>
                    <a
                      href="javascript:void(0)"
                      class="reviews__head"
                      @click="sortBy = 'useful'"
                      :class="{ reviews__head_active: sortBy === 'useful' }"
                    >По полезности</a>
                  </div>
                </div>
              </div>
              <div class="reviews__sidebar">
                <a href="javascript:void(0)" class="reviews__create attractive js-open-rating-popup">Написать отзыв</a>
                <div class="reviews__total">
                  <div class="reviews__total-header">Отзывы с оценкой</div>
                  <div
                    class="reviews__total-item"
                    v-for="(item, index) in rating"
                    :key="index"
                  >
                    <div class="br-wrapper">
                      <div class="br-widget br-readonly">
                        <a
                          v-for="(star, index) in rating"
                          :key="index"
                          href="javascript:void(0)"
                          :data-rating-value="star"
                          :data-rating-text="star"
                          :class="{
                            'br-selected': star <= item,
                            'br-current': star === item
                          }"></a>
                      </div>
                    </div>
                    <div class="reviews__total-summary">
                      {{reviewsSummary(item)}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <!----
              Похожие товары
       ---->
      <div class="product__advanced" v-if="item.match.length">
        <div class="title">
          <h2>
            <span>Похожие товары</span>
          </h2>
        </div>
        <div class="product__advanced-body">
          <div
              v-for="(prod, index) in item.match"
              :key="index"
              class="product__advanced-item"
          >
            <a
                class="thing thing_stretched"
                :href="`/catalog/product/${prod.id}`"
            >
              <div class="thing__symbols"></div>
              <img
                  :src="getImage('products', prod.file)"
                  :alt="prod.file"
                  class="thing__picture"
              />
              <div class="thing__header">{{prod.title}}</div>
              <div class="thing__features">
                Объем:
                <template v-for="(article, ind) in prod.articles">
                  <span :key="ind">{{ +article.volume }}</span>
                  {{ 'л' }}<template v-if="ind + 1 < prod.articles.length">, </template>
                </template>
              </div>
            </a>
          </div>
        </div>
      </div>


      <!----
             Статьи по теме
      ---->
      <div class="knowledge product__knowledge" v-if="item.events.length">
        <div class="title knowledge__title">
          <h2>
            <span>Статьи по теме</span>
          </h2>
        </div>
        <div class="knowledge__items">
          <div class="knowledge__items">
            <article-item
                v-for="(event, index) in item.events"
                :key="index"
                :init-data="event"
            />
          </div>
        </div>
      </div>

      <a href="/choose" class="clipart">
        <img src="@/assets/img/banners/banner1.jpg" alt class="clipart__picture">
      </a>
    </div>

    <modal-gallery
        id="single"
        :index="indexModal"
        :initData="currentArticleForImages"
    />

    <modal-gallery
        v-if="item.cert"
        id="cert"
        :index="indexModal"
        :initData="{data: item.cert}"
        :thisDocuments="true"
    />
  </div>
</template>

<script>
import pdf from 'vue-pdf-embed/dist/vue2-pdf-embed'
import ModalGallery from '../ModalGallery.vue'
import ArticleItem from '../Article.vue'
import utils from '../../utils';

export default {
  name: 'catalog-item',
  components: {
    pdf,
    ArticleItem,
    ModalGallery
  },
  data() {
    return {
      item: null,
      currentImg: null,
      currentArticle: null,
      currentArticleForImages: null,
      indexModal: 0,
      scrollEnabled: false
    }
  },
  methods: {
    async getInitDataProducts() {
      const { fetchData } = utils()
      let target = []
      let api_products = `products`
      if (this.$route.params.product) {

        target.push(`id=${this.$route.params.product}`)
        target.push(`breadcrumb=${true}`)
        target.push(`match=${true}`)
        target.push(`events=${true}`)

        api_products = api_products.concat(`?${target.join('&')}`)
        let response = await fetchData('get', api_products)

        if (response) {
          this.item = response;
          this.currentImg = response.file

          if (response.breadcrumb.length)
            this.$emit('breadcrumb', { breadcrumb : response.breadcrumb })
          setTimeout(() => this.$emit('load', { status : true }), 450)
        }
      }
    },
    concat_path(article, file) {
      return `${article.id}/${file}`
    },
    getImage(type, name) {
      const { getStaticImage } = utils()
      return getStaticImage(type, name)
    },
    showImages(article) {
      if (!this.currentArticle) {
        this.currentArticle = article
        this.currentArticleForImages = article
      }

      return article.id === this.currentArticleForImages.id
    },
    onChangeCurrentArticle(article) {
      if (article.files) {
        this.currentImg = this.concat_path(article, article.files[0])
        this.currentArticleForImages = article
      }
      this.currentArticle = article
    },
    // showReviews() {
    //   const rewiews = document.getElementById('reviews_container')

    //   if (rewiews.classList.contains('__active')) {
    //     rewiews.classList.remove('__active')
    //     rewiews.lastChild.style = "height: 0px; overflow: hidden;"
    //   } 
    //   else {
    //     rewiews.classList.add('__active')
    //     rewiews.lastChild.style = "height: auto; overflow: visible;"
    //   }
    // },
    // reviewsSummary(item) {
    //   const reviews = this.item.rewiews
    //   let count = 0

    //   reviews.forEach(review => {
    //     if ((review.star != undefined || review.star != null)) {
    //       if (review.star === item) count += 1
    //     }
    //   });

    //   return `${count} ${this.writingTheEnd(count)}`
    // },
    // writingTheEnd(c) {
    //   const count = c<20?c:c%10

    //   if (count === 1) return 'отзыв'
    //   else if (count >= 2 && count <= 4) return 'отзыва'
    //   else return 'отзывов'
    // },
    handleScroll: function () {
      if (this.scrollEnabled) {
        let scroll = window.scrollY + 100;
        let image = document.querySelector('.product__sidebar');
        let content = document.querySelector('.product__package');

        if (scroll < content.offsetTop) {
          image.style.position = 'absolute';
          image.style.top = '0px';
        } else if (scroll + image.offsetHeight > content.offsetTop + content.offsetHeight) {
          let bot_pos = content.offsetHeight - image.offsetHeight;
          image.style.position = 'absolute';
          image.style.top = bot_pos + 'px';
        } else if (scroll > content.offsetTop) {
          image.style.position = 'fixed';
          image.style.top = '100px';
        }
      }
    },
    handleResize: function () {
      this.scrollEnabled = document.body.clientWidth > 1023
      if (this.scrollEnabled) {
        let image = document.querySelector('.product__sidebar');
        image.style.position = 'static';
        image.style.top = 0;
      }
    }
  },
  directives: {
    scroll: {
      inserted: function (el, binding) {
        let f = function (evt) {
          if (binding.value(evt, el)) {
            window.removeEventListener('scroll', f)
          }
        }
        window.addEventListener('scroll', f)

      }
    },
    resize: {
      inserted: function (el, binding) {
        let f = function (evt) {
          if (binding.value(evt, el)) {
            window.removeEventListener('resize', f)
          }
        }
        window.addEventListener('resize', f)
      }
    }
  },
  beforeMount() {
    this.scrollEnabled = document.body.clientWidth > 1023
    this.getInitDataProducts()
  }
}
</script>