<template>
  <div class="pagination">
    <div class="pagination__content">
      <div class="pagination__items">
        <span
            class="pagination__item"
            v-for="page in maxPage"
            :key="page"
            :class="{ pagination__item_current: page === currentPage}"
            @click="switchPage(page)"
        >
          {{ page }}
        </span>
      </div>
      <div class="pagination__steps">
        <span
            class="pagination__prev arrow-left"
            v-show="currentPage > minPage"
            @click="switchPage(currentPage - 1)"
        >
          <svg class="icon icon-left">
            <use href="@/assets/img/elem/pointer.svg#icon-left"></use>
          </svg>
        </span>
        <span
            class="pagination__next arrow-right"
            v-show="currentPage < maxPage"
            @click="switchPage(currentPage + 1)"
        >
          <svg class="icon icon-left">
            <use href="@/assets/img/elem/pointer.svg#icon-right"></use>
          </svg>
        </span>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  props: {
    currentPage: null,
    countItems: null,
    limitItems: null
  },
  data() {
    return {
      minPage: 1,
      maxPage: Math.ceil(this.countItems / this.limitItems)
    }
  },
  methods: {
    switchPage(page) {
      this.$emit('switchPage', { page: page })
    }
  }
}
</script>