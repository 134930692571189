<template>

  <div class="carousel">
    <div class="carousel__header">
      {{ title }}
    </div>
    <div class="carousel__content">
      <div class="carousel__items document-read">

        <vue-slick-carousel ref="carousel" v-bind="settings" @afterChange="handleAfterChange">
          <a
                v-for="(item, index) in response.data"
                class="carousel__frame"
                @click="getDocument($event, index)"
                :key="index"
                :href="`#modal-gallery-images-${id}`"
                uk-toggle
            >
              <span class="file-pdf">
                 <pdf
                     :source="getImage('certificates', item.filename)"
                     :page="1"
                     @rendered="loading.push(index)"
                 />
              </span>
              <div v-if="!loading.includes(index)" uk-spinner></div>
          </a>
        </vue-slick-carousel>

      </div>
      <div class="carousel__nav" v-if="max_page > settings.slidesToShow">
        <div class="carousel__counter">
          <span class="carousel__current"> {{ page }} </span>
          <span class="carousel__separator"> / </span>
          <span class="carousel__total"> {{ max_page }} </span>
        </div>
        <div class="carousel__arrows">
          <button class="carousel__prev arrow-left" @click="showPrev">
            <svg class="icon icon-left">
              <use xlink:href="@/assets/img/elem/symbols.svg#icon-left"></use>
            </svg>
          </button>
          <button class="carousel__next arrow-right" @click="showNext">
            <svg class="icon icon-right">
              <use xlink:href="@/assets/img/elem/symbols.svg#icon-right"></use>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import pdf from 'vue-pdf-embed/dist/vue2-pdf-embed'
import utils from '../utils';

export default {
  props: {
    title: {
      type: String,
      default: 'Безопасность'
    },
    id: null,
    response: null,
  },
  components: {
    VueSlickCarousel,
    pdf
  },
  methods: {
    showNext() {
      if (this.delay) {
        this.delay = false;
        this.$refs.carousel.next()
        this.page = this.page + 1 > this.max_page ? 1 : this.page + 1
        setTimeout(() => this.delay = true, this.settings.speed);
      }
    },
    showPrev() {
      if (this.delay) {
        this.delay = false;
        this.$refs.carousel.prev()
        this.page = this.page - 1 < 1 ? this.max_page : this.page - 1
        setTimeout(() => this.delay = true, this.settings.speed);
      }
    },
    getImage(type, name) {
      const { getStaticImage } = utils()
      return getStaticImage(type, name)
    },
    getDocument(event, index) {
      this.$emit('link-index', { index })
    },
    resizeHandler() {
      if (screen.width <= 767) this.settings.slidesToShow = 2
      else if (screen.width <= 1023) this.settings.slidesToShow = 4
      else this.settings.slidesToShow = 5
    },
    handleAfterChange(event) {
        this.page = event+1
    },
  },
  data() {
    return {
      settings: {
        "dots": false,
        "arrows": false,
        "fade": false,
        "edgeFriction": 0.35,
        "infinite": true,
        "speed": 500,
        "slidesToShow": 5,
        "slidesToScroll": 1
      },
      delay: true,
      page: 1,
      max_page: 1,
      ready: false,
      loading: [],
    }
  },
  mounted() {
    this.max_page = this.response.count;
  },
  created() {
    window.addEventListener("resize", this.resizeHandler);
    this.resizeHandler()
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeHandler);
  },
}
</script>
