<template>
    <div class="certs">
        <div class="certs-title">
            Проверка наличия сертификата дистрибьютора
        </div>
        <div class="certs-input">
            <div class="certs-input-title">
                Введите ИНН компании:
            </div>
            <div class="certs-input-elem">
                <input class="certs-input-elem-input" placeholder="Ваш ИНН" type="number" v-model="inn">
            </div>
            <button class="certs-input-button" @click="checkData">Найти</button>
        </div>

        <div class="certs-error" v-if="errorIf">
            {{ error }}
        </div>

        <div class="certs-data" v-if="data">
            <div class="certs-data-inn">
                ИНН: {{ data.inn }}
            </div>
            <div class="certs-data-name">
                Наименование компании: {{ data.name }}
            </div>
        </div>

        <div class="certs-qr" v-if="data">
            <div class="certs-qr-title">
                Сертификат по <span><a style="color: blue; font-weight: 600;" :href="`/certs-check?inn=${inn}`">ссылке</a></span>:
            </div>
            <VueQRCodeComponent :text="valueQr" :size="size" level="H" />
        </div>
        
        <button class="certs-clear-content" style="width: fit-content; border: none; background: transparent; cursor: pointer; margin-top: 15px;"
            @click="inn = null, data=null, error='', errorIf=false"
            v-if="inn != null"
            aria-label="Сбросить поиск"
        >
            Сбросить поиск
        </button>
    </div>
</template>

<script>
import axios from 'axios';
import '@/assets/styles/certs.sass';

import VueQRCodeComponent from 'vue-qrcode-component'

export default {
  name: 'certs',
  data() {
    return {
        inn: null,
        data: null,
        valueQr: null,
        size: 200,
        errorIf: false,
        error: '',
    }
  },
  components: {
    VueQRCodeComponent,
  },
  methods: {
    async checkData() {
        try {
            await axios
                .get(`${process.env.VUE_APP_BACKEND_URL}/get/cert?inn=${this.inn}`)
                .then((response) => {
                    if(response.data.data.length <= 0) {
                        this.data = null;
                        this.errorIf = true;
                        this.error = 'Дистрибьютор по заданному ИНН не найден. Проверьте данные на корректность или обратитесь за помощью любым удобным для вас способом.'
                    } else {
                        this.errorif = false;
                        this.error = '';
                        this.data = response.data?.data[0];
                        this.valueQr = window.location.protocol + '//' + window.location.host + window.location.pathname + '-check' + '?inn=';

                        console.log(this.valueQr, window.location.protocol, window.location.host, window.location.pathname);

                        this.valueQr = this.valueQr+`${this.inn}`;
                    }
                });
        } catch (e) {
            console.log('We have the error', e)
        }
    }
  },
  metaInfo: {
    title: 'Проверить сертификат по ИНН',
    titleTemplate: `%s - ${process.env.VUE_APP_NAME}`,
  },
  watch: {
    inn(newInn) {
        if(newInn === null || newInn === '') {
            this.data = null;
        }
    }
  }
}

</script>

