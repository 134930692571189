import Vue from 'vue'
import Vuex from 'vuex';

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        cities: null,
        select_city: null,
        location: null,
        prom: null,
    },
    getters: {
        CITIES: state => {
            return state.cities;
        },
    },
    mutations: {
        SET_CITIES: (state, payload) => {
            state.cities = payload;
        },
        SELECT_CITY: (state, payload) => {
            state.select_city = payload;
        },
        SET_PROM: (state, payload) => {
            state.prom = payload;
        },
    },
});

export default store

