<template>
  <header id="header" class="header header_inner"
    :class="{'header_menu' : enable_slider}">
    <div
        id="header-primary"
        class="header__primary"
        :class="{'header__primary_fixed' : header_fixed, 'header__primary_nomenu': header_anim}"
        v-scroll="handleScroll"
        v-resize="handleResize"
    >
      <div class="header__container">
        <span class="header__logo">
          <a href="/" class="header__inside">
            <svg class="header__icon icon icon-logo_white">
              <use xlink:href="@/assets/img/elem/symbols.svg#icon-logo_white"></use>
            </svg>
            <div class="header__bg" data-animation="out"></div>
          </a>
        </span>
        <button type="button" class="aside-header__toggle" uk-toggle="target: #aside-header">МЕНЮ</button>

        <div class="header__rows">
          <div class="header__row">
            <div class="header__subsection header__subsection_concrete">
              <div class="header__role">
                <b>масла и технические жидкости</b> <br>
                <b>для японских автомобилей</b>
              </div>
              <div class="header__city">
                <select-city v-if="!is_mobile" />
              </div>
            </div>
            <div class="header__subsection">
              <a href="/choose" class="green green_lightweight">
                Подобрать масло TCL
              </a>
            </div>
          </div>

          <div class="header__menu" v-if="menu">
            <template v-for="(link, index) in menu">
              <span
                  class="header__menu-separator"
                  :key="`span-${index}`"
                  v-if="index >= 1"
              >
              </span>
              <div class="header__menu-item" :key="index">
                <a class="header__menu-link" :href="link.href" :class="{ 'header__menu-link--active':  !$route.path.indexOf(link.href) }">
                  {{ link.title }}
                </a>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div id="aside-header" class="aside-header dark-screen dark-screen--active" uk-offcanvas="overlay: true">
        <div class="uk-offcanvas-bar">
          <div class="aside-header__inner">
            <div class="header__rows">
              <div class="header__row">
                <div class="header__subsection header__subsection_concrete">
                  <div class="header__city">
                    <select-city v-if="is_mobile" />
                  </div>
                </div>
                <div class="header__subsection">
                  <a href="/choose" class="green green_lightweight">
                    Подобрать масло TCL
                  </a>
                </div>
              </div>
              <div class="header__menu" v-if="menu">
                <template v-for="(link, index) in menu">
                  <span
                      class="header__menu-separator"
                      :key="`span-${index}`"
                      v-if="index >= 1"
                  >
                  </span>
                  <div class="header__menu-item" :key="index">
                    <a class="header__menu-link" :href="link.href" :class="{ 'header__menu-link--active':  !$route.path.indexOf(link.href) }">
                      {{ link.title }}
                    </a>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    

    <slider-header v-if="enable_slider" />
  </header>
</template>

<script>
import SelectCity from "./SelectCity"
import SliderHeader from "./SliderHeader"
import MenuJson from '@/assets/data/menu.json';
import utils from '../utils';

export default {
  components: {
    SelectCity,
    SliderHeader
  },
  data() {
    return {
      is_mobile: false,
      header_fixed: false,
      header_anim: false,
      enable_slider: this.$route.name === 'main',
      menu: null,
    }
  },
  methods: {
    handleScroll: function () {
      this.header_fixed = window.scrollY > 50;
    },
    handleResize: function () {
      this.is_mobile = document.body.clientWidth < 1024
    },
    async countPromotion() {
      const { fetchData } = utils()
      return await fetchData('get', 'promotion/count')
    }
  },
  directives: {
    scroll: {
      inserted: function (el, binding) {
        let f = function (evt) {
          if (binding.value(evt, el)) {
            window.removeEventListener('scroll', f)
          }
        }
        window.addEventListener('scroll', f)
      }
    },
    resize: {
      inserted: function (el, binding) {
        let f = function (evt) {
          if (binding.value(evt, el)) {
            window.removeEventListener('resize', f)
          }
        }
        window.addEventListener('resize', f)
      }
    }
  },
  watch: {
    header_fixed: function() {
        this.header_anim = true
        setTimeout(() => this.header_anim = false, 350)
    }
  },
  async beforeMount() {
    let prom = await this.countPromotion()
    if (!prom) {
      this.menu = MenuJson.filter(function(item) { return item.title !== 'Акции' })
      this.$store.commit('SET_PROM', prom)
    } else {
      this.menu = MenuJson;
    }
    this.handleResize()
  }
}
</script>