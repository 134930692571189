<template>
  <div id='choose'>
    <div class="container">
      <div class="container__liner">
        <div id="navigaton">
          <div class="breadcrumbs">
            <a href='/' class="breadcrumbs-item">Главная</a>
            <span class="breadscrumbs-item" >Подобрать товар по модели авто</span>
          </div>
        </div>
        <div class="title">
            <h1 id="pagetitle" class="entry">
              <span> Подобрать товар по модели авто</span>
            </h1>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="container__liner">
        <div class="stock">
          <form>
            <widget-select
              :button="false"
              @selection="modific_id = $event"
            />
          </form>
          <choose-table
            v-if="data && !loading"
            :initData="data"
          />
          <div v-else-if="loading" class="loader loader-catalog-list">
            <div class="box box-flex" style="margin-bottom: 50px">
              <div class="item load-skeleton" style="height: 75px"></div>
              <div class="item load-skeleton" style="height: 400px"></div>
              <div class="item load-skeleton" style="height: 400px"></div>
            </div>
          </div>
          <Feedback :partner="true" />
        </div>
      </div>
    </div>
    <widget-map />
  </div>
</template>

<script>
import WidgetSelect from '../components/WidgetSelect'
import ChooseTable from '../components/ChooseTable'
import Feedback from "../components/Feedback"
import WidgetMap from '../components/WidgetMap'
import utils from '../utils'

export default {
  components: {
    WidgetSelect,
    ChooseTable,
    Feedback,
    WidgetMap
  },
  data() {
    return {
      modific_id: null,
      data: null,
      loading: false,
    }
  },
  watch: {
    modific_id(value) {
      if (value) {
        this.getInitDataSelection()
      }
    }
  },
  methods: {
    async getInitDataSelection() {
      const { fetchData } = utils()
      this.data = null
      this.loading = true

      let target = `selection/products?modific=${this.modific_id}`

      let response = await fetchData('get', target)
      if (response) this.data = response.data
      else this.data = []
      this.loading = false
    },
  },
  metaInfo: {
    title: 'Подобрать товар по модели авто',
    titleTemplate: `%s - ${process.env.VUE_APP_NAME}`,
  },
}
</script>