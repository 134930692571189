<template>
  <div :class="button?'variants__items':'stock__sorting'">
    <div :class="button?'variants__item':'stock__sorting-item'">
      <span class="ui-selectmenu-button ui-button compact-select"
            @click="list_open = 'mark'" select-item="product">
        <span class="ui-selectmenu-icon ui-icon"></span>
        <span class="ui-selectmenu-text uk-text-truncate">{{select_mark==null?'Марка автомобиля':select_mark.title}}</span>
          <select-item
              id="product"
              state="Марка автомобиля"
              :open="list_open === 'mark'"
              :data="marks"
              :queryItem="init_mark"
              @selected="select_mark = $event.item, init_mark = null"
              @open="list_open = $event.enabled"
          />
      </span>
    </div>
    <div :class="button?'variants__item':'stock__sorting-item'">
      <span class="ui-selectmenu-button ui-button compact-select"
            @click="list_open = 'model'" select-item="product">
        <span class="ui-selectmenu-icon ui-icon"></span>
        <span class="ui-selectmenu-text uk-text-truncate">{{select_model==null?'Модель автомобиля':select_model.title}}</span>
          <select-item
              id="product"
              state="Модель автомобиля"
              :open="list_open === 'model'"
              :data="models"
              :queryItem="init_model"
              @selected="select_model = $event.item, init_model = null"
              @open="list_open = $event.enabled"
          />
      </span>
    </div>
    <div :class="button?'variants__item':'stock__sorting-item'">
      <span class="ui-selectmenu-button ui-button compact-select"
            @click="list_open = 'set'" select-item="product">
        <span class="ui-selectmenu-icon ui-icon"></span>
        <span class="ui-selectmenu-text uk-text-truncate">{{select_set==null?'Комплектация':select_set.title}}</span>
          <select-item
              id="product"
              state="Комплектация"
              :open="list_open === 'set'"
              :data="set"
              :queryItem="init_set"
              @selected="select_set = $event.item, init_set = null"
              @open="list_open = $event.enabled"
          />
      </span>
    </div>
    <div class="variants__item" v-if="button">
      <button
          type="button"
          class="green"
          @click="testSelection"
      >
        Подобрать товар
      </button>
    </div>

    <div
        v-if="button"
        :class="{'select-notice--show' : errorMessage}"
        class="select-notice"
    >
      <span v-html="errorMessage"></span>
    </div>
  </div>
</template>

<script>
import SelectItem from './SelectItem'
import utils from '../utils'

export default {
  name: 'widget-select',
  components: {
    SelectItem
  },
  props: {
    button: {
      object: Boolean,
      default: true,
    }
  },
  computed: {
    buildQuery() {
      let query = ''
      if (this.select_mark) query += `?mark=${this.select_mark.id}`
      if (this.select_model) query += `&model=${this.select_model.id}`
      if (this.select_set) query += `&set=${this.select_set.id}`
      return query
    }
  },
  data() {
    return {
      list_open: false,
      select_model: null,
      select_mark: null,
      select_set: null,
      init_model: null,
      init_mark: null,
      init_set: null,
      models: null,
      marks: null,
      set: null,
      errorMessage: null,
    }
  },
  watch: {
    select_mark: function() {
      this.select_model = null
      this.select_set = null
      this.models = null
      this.set = null
      if (this.errorMessage) this.testSelection(false)
      this.getInitDataModels()
    },
    select_model: function() {
      this.select_set = null
      this.set = null
      if (this.errorMessage) this.testSelection(false)
      this.getInitDataModifics()
    },
    select_set: function(value) {
      if (!this.button && value) {
        this.$emit('selection', this.select_set.id)
      }
      if (this.errorMessage) this.testSelection(false)
    },
  },
  methods: {
    testSelection(ref = true) {
      this.errorMessage = null
      let messages = []

      if (!this.select_mark) messages.push('<b>марка авто</b>')
      if (!this.select_model) messages.push('<b>модель авто</b>')
      if (!this.select_set) messages.push('<b>комплектация авто</b>')

      if (messages.length) {
        this.errorMessage = 'Не выбрана ' + messages.join(', ');
        return false
      }

      if (ref) {
        this.$router.push(`/choose${this.buildQuery}`)
      }
    },
    fetchQuery() {
      if (this.$route.query.mark) this.init_mark = this.$route.query.mark
      if (this.$route.query.model) this.init_model = this.$route.query.model
      if (this.$route.query.set) this.init_set = this.$route.query.set
    },
    async getInitDataMarks() {
      const { fetchData } = utils()

      let target = `selection/marks`

      let response = await fetchData('get', target)
      if (response) this.marks = response.data
    },
    async getInitDataModels() {
      if (this.select_mark) {
        const {fetchData} = utils()

        let target = `selection/models?mark=${this.select_mark.id}`

        let response = await fetchData('get', target)
        if (response) this.models = response.data
      }
    },
    async getInitDataModifics() {
      if (this.select_model) {
        const {fetchData} = utils()

        let target = `selection/modifics?model=${this.select_model.id}`

        let response = await fetchData('get', target)
        if (response) this.set = response.data
      }
    },
  },
  beforeMount() {
    this.getInitDataMarks()
    this.fetchQuery()
  },
}
</script>