<template>
  <div
      id="expert"
      class="expert expert_elegant"
      :class="{'__animated' : expert_anim}"
      v-if="content && content.count"
      v-scroll="handleScroll"
  >
    <div class="expert__container">
      <div class="expert__inside">
        <div class="expert__title title">
          <h2>
            <span>{{ title }}</span>
          </h2>
        </div>
        <div class="expert__collection">
          <div class="expert__items">
            <vue-slick-carousel ref="articles" v-bind="settings" @afterChange="handleAfterChange">
              <div
                  v-for="(item, index) in content.data"
                  :key="index"
                  class="expert__item"
              >
                <a :href="`/articles/${item.id}`">
                  <div class="expert__article">
                    <div class="expert__frame">
                      <img :src="getImage('news', item.filename)" :alt="item.filename"/>
                      <div class="expert__date">
                        {{ formatDate(item.date) }}
                      </div>
                    </div>
                    <div class="expert__header">
                      {{ item.title }}
                    </div>
                    <div class="expert__text" v-html="formatText(item.text)"></div>
                  </div>
                </a>
              </div>
            </vue-slick-carousel>
            <a href="/articles" class="expert__all fashion">
              Смотреть все статьи
            </a>
          </div>
          <div class="expert__nav">
            <div class="expert__counter">
              <span id="expert-current" class="expert__current"> {{ page }} </span>
              <span class="expert__separator"> / </span>
              <span class="expert__total"> {{ max_page }} </span>
            </div>
            <div class="expert__arrows">
              <button class="expert__prev arrow-left" @click="showPrev">
                <svg class="icon icon-left">
                  <use xlink:href="@/assets/img/elem/symbols.svg#icon-left"></use>
                </svg>
              </button>
              <button class="expert__next arrow-right" @click="showNext">
                <svg class="icon icon-right">
                  <use xlink:href="@/assets/img/elem/symbols.svg#icon-right"></use>
                </svg>
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import utils from '../utils';

export default {
  components: {
    VueSlickCarousel
  },
  props: {
    title: {
      type: String,
      default: 'Статьи'
    },
    type: null,
  },
  data() {
    return {
      settings: {
        "dots": false,
        "arrows": false,
        "fade": false,
        "edgeFriction": 0.35,
        "infinite": true,
        "speed": 500,
        "slidesToShow": 3,
        "slidesToScroll": 1,
      },
      limit: 10,
      delay: true,
      page: 1,
      max_page: 1,
      content: null,
      expert_anim: false
    }
  },
  directives: {
    scroll: {
      inserted: function (el, binding) {
        let f = function (evt) {
          if (binding.value(evt, el)) {
            window.removeEventListener('scroll', f)
          }
        }
        window.addEventListener('scroll', f)
      }
    }
  },
  methods: {
    async getArticles() {
      const { fetchData } = utils()
      let target = `news?limit=${this.limit}`

      if (this.type) {
        target = target.concat(`&type=${this.type}`)
      }

      this.content =  await fetchData('get', target)
      if (this.content) {
        this.max_page = this.content.count;
      }
    },
    getImage(type, name) {
      const { getStaticImage } = utils()
      return getStaticImage(type, name)
    },
    formatText(value) {
      return value.replace(/<\/?[^>]+(>|$)/g, '')
    },
    formatDate(data) {
      return this.$moment(data.date).format('DD MMMM YYYY');
    },
    showNext() {
      if (this.delay) {
        this.delay = false;
        this.$refs.articles.next()
        this.page = this.page + 1 > this.max_page ? 1 : this.page + 1
        setTimeout(() => this.delay = true, this.settings.speed)
      }
    },
    showPrev() {
      if (this.delay) {
        this.delay = false;
        this.$refs.articles.prev()
        this.page = this.page - 1 < 1 ? this.max_page : this.page - 1
        setTimeout(() => this.delay = true, this.settings.speed)
      }
    },
    handleAfterChange(event) {
      this.page = event + 1
    },
    handleScroll: function () {
      let fromTop = window.scrollY;
      let section = document.querySelector('#expert');
      let range = 750;
      if (
          section &&
          section.offsetTop - range  <= fromTop &&
          section.offsetTop + section.offsetHeight - range > fromTop
      )
      this.expert_anim = true;
    },
    resizeHandler() {
      if (screen.width <= 767) this.settings.slidesToShow = 1
      else if (screen.width <= 1023) this.settings.slidesToShow = 2
      else this.settings.slidesToShow = 3
    }
  },
  beforeMount() {
    this.getArticles()
  },
  mounted() {

  },
  created() {
    window.addEventListener("resize", this.resizeHandler);
    this.resizeHandler()
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeHandler);
  },
}
</script>
