<template>
  <div class="knowledge__item">
    <a :href="`/articles/${initData.id}`" class="knowledge__unit clearfix">
      <div class="knowledge__ref">
        <img
            :src="getImage('news', initData.filename)"
            :alt="initData.filename"
        />
      </div>
      <div class="knowledge__content">
        <div class="knowledge__date">
          {{ formatDate(initData.date) }}
        </div>
        <div class="knowledge__header">
          {{ initData.title }}
        </div>
        <div
            class="knowledge__text"
            v-html="initData.text"
        >
        </div>
      </div>
      <div class="knowledge__tags" v-if="initData.tags.length">
        <a
            v-for="(tag, ind) in initData.tags"
            :key="ind"
            :href="`/articles?tag=${tag.id}`"
            class="knowledge__tag inviting"
        >
          {{ tag.title }}
        </a>
      </div>
    </a>
  </div>
</template>

<script>
import utils from '../utils';

export default {
  props: {
    initData: null
  },
  methods: {
    formatDate(data) {
      return this.$moment(data.date).format('DD MMMM YYYY')
    },
    getImage(type, name) {
      const { getStaticImage } = utils()
      return getStaticImage(type, name)
    },
  }
}
</script>