<template>
  <div class="articles">
    <div class="container">
      <div class="container__liner">
        <div id="navigation">
          <div class="breadcrumbs">
            <a itemprop="itemListElement" href="/" class="breadcrumbs-item">Главная</a>
            <span class="breadcrumbs-item">{{ actionsPage?'Акции':'Новости и статьи' }}</span>
          </div>
        </div>
        <div class="title">
          <h1 id="pagetitle">
            <span>{{ actionsPage?'Акции':'Новости и статьи' }}</span>
          </h1>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="container__liner container__pd-bm">
        <div class="filtration">

          <div class="filtration__primary" v-if="!actionsPage">
            <label class="custom-ui custom-ui_solid">
              <input
                  type="radio"
                  class="custom-ui__input"
                  :checked="!type"
              />
              <span class="custom-ui__text" @click="setFilterType()">
                Все
              </span>
            </label>

            <label class="custom-ui custom-ui_solid">
              <input
                  type="radio"
                  class="custom-ui__input"
                  :checked="type === 1"
              />
              <span class="custom-ui__text" @click="setFilterType(1)">
                Новости/События
              </span>
            </label>
            <label class="custom-ui custom-ui_solid">
              <input
                  type="radio"
                  class="custom-ui__input"
                  :checked="type === 2"
              >
              <span class="custom-ui__text" @click="setFilterType(2)">
                Экспертные статьи
              </span>
            </label>
          </div>
          <div
              class="filtration__secondary"
              v-if="tags"
              :key="selectedTags.length"
          >
            <div class="filtration__explanation">
              Фильтрация по метками:
            </div>

            <label
                class="filtration__unit custom-ui custom-ui_elegant"
                :class="{'custom-ui-active': selectedTags.find(item => { return item.id === tag.id })}"
                v-for="(tag, index) in tags"
                :key="index"
                @click="setFilterTag(tag, $event)"
            >
              <span class="custom-ui__text">
                {{ tag.title }}
              </span>
            </label>
          </div>
        </div>

        <div v-if="loading" class="loader">
          <div class="box box-flex">
            <div class="item load-skeleton" style="height: 390px"></div>
            <div class="item item-flex" style="height: 250px">
              <div class="item item-full item-col-2 load-skeleton"></div>
              <div class="item item-full item-col-2 load-skeleton"></div>
            </div>
          </div>
        </div>

        <div v-else-if="data" class="knowledge knowledge_has-leader">
          <div class="knowledge__items">

            <div v-if="!data.length" class="knowledge__empty">
              {{ actionsPage?'Акции не найдены':'Статьи не найдены' }}
            </div>

            <div
                class="knowledge__item"
                v-for="(item, index) in data"
                :key="index"
            >
              <a :href="`${href}/${item.id}`" class="knowledge__unit clearfix">
                <div class="knowledge__ref">
                  <img :src="getImage('news', item.filename)" :alt="item.filename"/>
                </div>
                <div class="knowledge__content">
                  <div class="knowledge__date">
                    {{ formatDate(item.date) }}
                  </div>
                  <div class="knowledge__header">
                    {{ item.title }}
                  </div>
                  <div class="knowledge__text" v-html="item.text"></div>
                </div>
                <div class="knowledge__tags" v-if="item.tags.length">
                  <label
                      v-for="(tag, ind) in item.tags"
                      :key="ind"
                      class="knowledge__tag inviting"
                      @click="setFilterTag(tag, $event)"
                  >
                    {{ tag.title }}
                  </label>
                </div>
              </a>
            </div>
          </div>

          <pagination
              v-if="count > data.length"
              :currentPage="page"
              :countItems="count"
              :limitItems="limit"
              @switchPage="page = $event.page"
          />

        </div>

      </div>
    </div>

  </div>
</template>

<script>
import Pagination from '../components/Pagination'
import utils from '../utils';

export default {
  components: {
    Pagination
  },
  data() {
    return {
      loading: null,
      tags: null,
      type: null,
      selectedTags: [],
      limit: 5,
      page: 1,
      data: null,
      count: null
    }
  },
  computed: {
    actionsPage() {
      if (this.$route.name === 'actions') return true
      return false
    },
    href() {
      if(this.$route.path.includes('articles')) return '/articles'
      else if(this.$route.path.includes('actions')) return '/actions'
      return '/'
    },
  },
  methods: {
    async getDataArticles() {
      this.loading = true
      const { fetchData } = utils()
      let targetTags = ''
      let targetType = ''
      if (this.actionsPage) targetType = `&type=3`
      else targetType = this.type ? `&type=${this.type}` : ''

      this.selectedTags.forEach(tag => {
        targetTags = targetTags.concat(`&tags[]=${tag.id}`)
      })

      let target = `news?limit=${this.limit}&page=${this.page}`
      target = target.concat(targetType, targetTags)

      let response =  await fetchData('get', target)
      if (response) {
        this.data = response.data
        this.count = response.count
      }
      setTimeout(() => this.loading = false, 450);
    },
    async getInitDataTags() {
      const { fetchData } = utils()
      let response =  await fetchData('get', 'tags')
      if (response) {
        this.tags = response.data

        if (this.$route.query.tag) {
          let id = this.$route.query.tag
          this.selectedTags = this.tags.filter(item => { return item.id == id })
        }
      }
    },
    setFilterTag(tag, event) {
      event.preventDefault()
      let tags = this.selectedTags
      let len = tags.length

      tags = tags.filter((item) => {
        return item.id !== tag.id
      })

      if (len === tags.length) tags.push(tag)
      this.selectedTags = tags
      this.page = 1
      this.getDataArticles()
    },
    setFilterType(type = null) {
      this.type = type
      this.page = 1
      this.getDataArticles()
    },
    getImage(type, name) {
      const { getStaticImage } = utils()
      return getStaticImage(type, name)
    },
    formatDate(data) {
      return this.$moment(data.date).format('DD MMMM YYYY')
    }
  },
  watch: {
    page() {
      this.getDataArticles()
    }
  },
  async beforeMount() {
    if (this.$route.query.type) {
      if (+this.$route.query.type === 3) this.$router.push('/actions')
      this.type = +this.$route.query.type
    }
    await this.getInitDataTags()
    await this.getDataArticles()
  },
  metaInfo: {
    title: 'Новости и статьи',
    titleTemplate: `%s - ${process.env.VUE_APP_NAME}`,
  },
}

</script>
