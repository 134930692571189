<template>
  <div id="catalog">
    <div class="container">
      <div class="container__liner">
        <div id="navigaton">
          <div class="breadcrumbs">
            <a
                v-for="(item, index) in navigationPath.path"
                :key="index"
                :href=item.link
                class="breadcrumbs-item"
            >
              {{ item.label }}
            </a>
            <span class="breadscrumbs-item">{{navigationPath.target}}</span>
          </div>
        </div>
        <div class="title">
          <div class="loader" v-if="loading">
            <div class="box-flex" style="height: 110px; width: 100%; margin-bottom: -30px; max-width: 1170px;">
              <div class="item item-bg load-skeleton"></div>
            </div>
          </div>

          <h1 v-else id="pagetitle">
            <span>{{navigationPath.target}}</span>
          </h1>
        </div>
      </div>
    </div>

    <catalog-list
        v-if="$route.name === 'catalog'"
        @breadcrumb="buildNewPath($event.breadcrumb)"
    />

    <div v-if="$route.name === 'product'">

      <div class='container' v-if="loading">
        <!-- skeleton -->
        <div class="container__liner loader loader-catalog">
          <div class="box box-flex">
            <div class="item item-flex item-full" style="max-width: 1170px; justify-content: left;">
              <div class="item item-col-2 item-full box-flex" style="width: 500px; padding-right: 40px">
                <div class="item item-bg load-skeleton"></div>
                <div class="item item-md item-flex">
                  <div class="item item-flex item-col-3 load-skeleton"></div>
                  <div class="item item-flex item-col-3 load-skeleton"></div>
                  <div class="item item-flex item-col-3 load-skeleton"></div>
                </div>
              </div>
              <div class="item item-col-2 item-full box-flex" style="width: 670px">
                <div class="item item-sm load-skeleton"></div>
                <div class="item item-bg load-skeleton"></div>
                <div class="item item-bg load-skeleton"></div>
                <div class="item item-md load-skeleton"></div>
                <div class="item item-md load-skeleton"></div>
              </div>
            </div>
          </div>
        </div>
        <!-- end skeleton -->
      </div>

      <catalog-item
          v-show="!loading"
          @load="loading = !$event.status"
          @breadcrumb="buildNewPath($event.breadcrumb)"
      />
      <widget-map />
    </div>

  </div>
</template>

<script>
import catalogList from '../components/catalog/catalog-list.vue'
import catalogItem from '../components/catalog/catalog-item.vue'
import WidgetMap from '../components/WidgetMap'

export default {
  name: 'catalog-container',
  components: {
    catalogList,
    catalogItem,
    WidgetMap
  },
  data() {
    return {
      loading: this.$route.name === 'product',
      navigationPath: {
        path: [
          {
            label: 'Главная',
            link: '/'
          }
        ],
        target: 'Каталог товаров',
      },
    }
  },
  methods: {
    buildNewPath(breadcrumb) {
      this.navigationPath = {
        path: [
          { label: 'Главная', link: '/' },
          { label: 'Каталог товаров', link: '/catalog' }
        ],
        target: '',
      }

      breadcrumb.forEach((item, index) => {
        if (index === breadcrumb.length - 1) {
          this.navigationPath.target =  item.title
        } else {
          let path = {
            label : item.title,
            link: '/catalog/' + item.id,
          }
          this.navigationPath.path.push(path)
        }
      });
    },
  },
  metaInfo: {
    title: 'Каталог товаров',
    titleTemplate: `%s - ${process.env.VUE_APP_NAME}`,
  },
  watch: {
    navigationPath: {
      handler: function (data) {
        document.title = `${data.target} - ${process.env.VUE_APP_NAME}`;
      },
      deep: true
    },
  }
}
</script>