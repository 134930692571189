<template>
  <div id="certificates">
    <div class="container">
      <div class="container__liner">
        <div id="navigaton">
          <div class="breadcrumbs">
            <a href='/' class="breadcrumbs-item">Главная</a>
            <span class="breadscrumbs-item" >Сертификаты</span>
          </div>
        </div>
        <div class="title">
          <h1 id="pagetitle">
            <span>Сертификаты</span>
          </h1>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="container__liner">
        <div class="slideshow">
          <div class="slideshow__item">
            <!--<certificate-container
              v-for="(certificate, index) in certificates"
              :key="index"
              :initData="certificate"
            /> -->

            <slider-certificates
                id="defeat"
                v-if="certDefeat && certDefeat.count"
                title="Безопасность"
                :response="certDefeat"
                @link-index="indexModal = $event.index"
            />

            <slider-certificates
                id="standard"
                v-if="certStandard && certStandard.count"
                title="Стандарты"
                :response="certStandard"
                @link-index="indexModal = $event.index"
            />

          </div>
        </div>
      </div>

      <modal-gallery
          id="defeat"
          :index="indexModal"
          :initData="certDefeat"
          :thisDocuments="true"
      />

      <modal-gallery
          id="standard"
          :index="indexModal"
          :initData="certStandard"
          :thisDocuments="true"
      />

    </div>
    <widget-map />
  </div>
</template>

<script>
import WidgetMap from '../components/WidgetMap'
import SliderCertificates from '../components/SliderCertificates'
import ModalGallery from '../components/ModalGallery.vue'
import utils from '../utils';

export default {
  name: 'certificates-container',
  components: {
    WidgetMap,
    ModalGallery,
    SliderCertificates
  },
  data() {
    return {
      certDefeat: null,
      certStandard: null,
      indexModal: 0
    }
  },
  methods: {
    async getInitDataCertificates(type = 1) {
      const { fetchData } = utils()

      let target = `certificates?type=${type}`
      return await fetchData('get', target)
    },
    handlerCert(event, data) {
      this.index = event.index
      this.data = data
    }
  },
  async beforeMount() {
    this.certDefeat = await this.getInitDataCertificates(1)
    this.certStandard = await this.getInitDataCertificates(2)
  },
  metaInfo: {
    title: 'Сертификаты',
    titleTemplate: `%s - ${process.env.VUE_APP_NAME}`,
  },
}
</script>