import Vue from 'vue'
import VueMeta from 'vue-meta'
import VueMask from 'v-mask'
import VueLocalStorage from 'vue-localstorage'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueYandexMetrika from 'vue-yandex-metrika'
import moment from 'moment'
import App from './App.vue'
import router from './router'
import store from './storage'
import 'uikit'
import 'uikit/dist/css/uikit.css'
import "./assets/styles/uikit-custom.scss"
import "./assets/style.sass"

Vue.config.productionTip = false
Vue.prototype.$moment = moment
Vue.use(VueReCaptcha, { siteKey: `${process.env.VUE_APP_CAPTCHA_SITE_KEY}` })
Vue.use(VueMask)
Vue.use(VueLocalStorage)
Vue.use(VueMeta)

Vue.use(VueYandexMetrika, {
  id: process.env.VUE_APP_METRIC_YANDEX,
  router: router,
  env: process.env.NODE_ENV
})

moment.locale('ru')

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
