<template>
  <div class="map">
    <div class="container">
      <div class="container__liner">
        <div id="navigaton">
          <div class="breadcrumbs">
            <a href='/' class="breadcrumbs-item">Главная</a>
            <span class="breadscrumbs-item">Где купить или заменить</span>
          </div>
        </div>
      </div>
    </div>
    <widget-map />
  </div>
</template>

<script>
import WidgetMap from '../components/WidgetMap'

export default {
  components: {
    WidgetMap
  },
  metaInfo: {
    title: 'Где купить или заменить',
    titleTemplate: `%s - ${process.env.VUE_APP_NAME}`,
  },
}
</script>