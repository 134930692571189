<template>
  <div class="about">
    <div class="about__container container">
      <div class="about__inside">
        <div class="about__slider">

          <vue-slick-carousel
              ref="about"
              v-bind="settings"
          >
            <div class="about__slide">
              <div class="about__content">
                <div class="about__header">
                  О компании TCL
                </div>
                <div class="about__description">
                  TANIKAWA YUKA KOGYO производит моторные и трансмиссионные масла, антифризы и тормозную жидкость под
                  брендом TCL, начиная с 1949 года. <br>
                  Качество продукции постоянно совершенствуется в соответствии с изменениями в отрасли автомобилестроения.
                </div>
              </div>
              <div class="about__background">
                <img src="@/assets/img/widget/widgetabout-5.jpg" alt="image"/>
              </div>
              <div class="about__screen0"
                   :style="`background-image: url(${require('@/assets/img/widget/widgetabout-6.jpg')})`">
              </div>
              <div class="about__screen1">
                <img src="@/assets/img/widget/widgetabout-3.jpg" alt="image"/>
              </div>
              <div class="about__screen2">
                <img src="@/assets/img/widget/widgetabout-4.jpg" alt="image"/>
              </div>
              <div class="about__decor"></div>
            </div>

            <div class="about__slide">
              <div class="about__content">
                <div class="about__header">
                  О компании TCL
                </div>
                <div class="about__description">
                  Ежемесячно компания производит более 650 тонн готовой продукции, которая реализуется как на
                  внутреннем рынке Японии, так и экспортируется в такие страны как Россия, США, Китай, Малайзия,
                  Корея и другие.
                </div>
              </div>
              <div class="about__background">
                <img src="@/assets/img/widget/widgetabout-6.jpg" alt="image"/>
              </div>
              <div class="about__screen0"
                   :style="`background-image: url(${require('@/assets/img/widget/widgetabout-5.jpg')})`">
              </div>
              <div class="about__screen1">
                <img src="@/assets/img/widget/widgetabout-1.jpg" alt="image"/>
              </div>
              <div class="about__screen2">
                <img src="@/assets/img/widget/widgetabout-2.jpg" alt="image"/>
              </div>
              <div class="about__decor"></div>
            </div>
          </vue-slick-carousel>

          <div class="about__ui">
            <div class="about__counter about__counter-white">
              <span class="about__current"> {{ page }}	</span>
              <span class="about__separate about-white"> / </span>
              <span class="about__total about-white"> {{ max_page }} </span>
            </div>
            <div class="about__switchers">
              <button class="about__prev arrow-left arrow-white" @click="showPrev">
                <svg class="icon icon-left">
                  <use xlink:href="@/assets/img/elem/symbols.svg#icon-left"></use>
                </svg>
              </button>
              <button class="about__next arrow-right arrow-white" @click="showNext">
                <svg class="icon icon-right">
                  <use xlink:href="@/assets/img/elem/symbols.svg#icon-right"></use>
                </svg>
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'

export default {
  components: {
    VueSlickCarousel
  },
  data() {
    return {
      settings: {
        "dots": false,
        "arrows": false,
        "fade": true,
        "edgeFriction": 0.35,
        "infinite": true,
        "speed": 500,
        "slidesToShow": 1,
        "slidesToScroll": 1
      },
      delay: true,
      page: 1,
      max_page: 2,
    }
  },
  methods: {
    showNext() {
      if (this.delay) {
        this.delay = false;
        this.$refs.about.next()
        this.page = this.page + 1 > this.max_page ? 1 : this.page + 1
        setTimeout(() => this.delay = true, this.settings.speed);
      }
    },
    showPrev() {
      if (this.delay) {
        this.delay = false;
        this.$refs.about.prev()
        this.page = this.page - 1 < 1 ? this.max_page : this.page - 1
        setTimeout(() => this.delay = true, this.settings.speed);
      }
    },
  }
}
</script>